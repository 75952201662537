import React from 'react';
import DocumentContainer from './containers/DocumentContainer';
import NotificationContainer from './containers/NotificationContainer';
import UserContainer from './containers/UserContainer';
import PortfolioContainer from './containers/PortfolioContext';

type Props = { children: React.ReactNode };

const Store = ({ children }: Props) => {
    return (
        <NotificationContainer>
            <UserContainer>
                <PortfolioContainer>
                    <DocumentContainer>
                        {children}
                    </DocumentContainer>
                </PortfolioContainer>
            </UserContainer>
        </NotificationContainer>
    );
}

export default React.memo(Store);