import { useState } from 'react';
import styled from 'styled-components';
import ClosedEyeIcon from 'static/images/icons/closed-eye.svg';
import EyeIcon from 'static/images/icons/eye.svg';

const PasswordContainer = styled.div`
    position: relative;

    input{
        width: 100% ;
        padding-right: 40px ;
    }

    .eye{
        position: absolute;
        right: 10px;
        top: 15px;
        cursor: pointer ;
    }
`;

interface IPasswordInput {
    password: string;
    setPassword: (password: string) => void;
    placeholder: string;
}

function PasswordInput({ password, setPassword, placeholder = '' }: IPasswordInput) {

    const [showPassword, setShowPassword] = useState(false);

    const tooglePasswordHide = () => {
        setShowPassword(!showPassword);
    }

    return (
        <PasswordContainer>
            <input name="password" required value={password} type={showPassword ? 'text' : 'password'}
                onChange={e => { setPassword(e.target.value); e.target.setCustomValidity("") }}
                onInvalid={e => (e.target as HTMLInputElement).setCustomValidity("Le mot de passe n'est pas valide")}
                placeholder={placeholder}>
            </input>

            <img src={showPassword ? EyeIcon : ClosedEyeIcon}
                onClick={tooglePasswordHide} width={showPassword ? '23' : '26'} alt="eye" className="eye" />
                
        </PasswordContainer>
    );
}

export default PasswordInput