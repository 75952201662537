import ReactDOM from 'react-dom';
import SnackBar from 'components/common/SnackBar';

export const getMessageFromCode = (statusCode: string) => {
    switch (statusCode) {
        case "EXPIRED_TOKEN":
            return "Le lien de connexion a expiré, veuillez demander un autre lien de connexion";
        case "INVALID_TOKEN":
            return "Le lien de connexion n'est pas valide, veuillez demander un autre lien de connexion";
        case "INTERNAL_ERROR":
            return "Une erreur s'est produite, veuillez réessayer plus tard";
        case "SALE_PORTFOLIO_NOT_FOUND":
            return "Impossible de trouver un dossier de vente";
        case "EXPIRED_SHARE_TOKEN":
            return "Le lien de partage a expiré, veuillez demander un autre lien de partage";
        case "INVALID_SHARE_TOKEN":
            return "Le lien de partage n'est pas valide, veuillez demander un autre lien de partage";
        case "FILES_NOT_FOUND":
            return "Il n'y a aucun document validé à télécharger. Vous pouvez laisser Paperas.se rechercher vos documents ou les charger manuellement";
        case "EXPIRED_INVITATION_LINK":
            return "Désole, le lien d'invitation a expiré, veuillez demander un nouveau lien";
        case "INVALID_INVITATION_LINK":
            return "Désole, le lien d'invitation n'est pas valide, veuillez demander un nouveau lien";
        case "VALIDATION_ERROR":
            return "Une erreur de validation s'est produite";
        default:
            return "Oops, une erreur s'est produite!";
    }
}

// Returns the a valid error message
export const getErrorMessage = (error: any) => {
    try {
        let responseData = error?.response?.data;
        
        if (typeof responseData === "string") {
            return responseData;
        }
        
        // Check DRF validation errors
        if (typeof responseData === "object") {
            if ('message' in responseData) {
                responseData = responseData['message'];
            }

            if ("non_field_errors" in responseData) {
                return responseData["non_field_errors"].join(', ');
            }

            return Object.values(responseData).join(', ');
        }
    } catch (error) {
        return "";
    }
}

type SnackbarMessageType = 'error' | 'info' | 'warning' | 'success';

const triggerSnackbar = (title: string, message: string = "", messageType: SnackbarMessageType) => {
    const validMessageTypes = ['error', 'info', 'warning', 'success'];
    if (!validMessageTypes.includes(messageType)) {
        throw Error("Invalid snackbar message type");
    }
    ReactDOM.render(
        <SnackBar messageType={messageType} timer={10000} title={title} message={message} />,
        document.getElementById('notification-fixed-container')
    );
}

export const showErrorMessage = (title: string, message: string = "") => {
    triggerSnackbar(title, message, 'error');
}

export const showInfoMessage = (title: string, message: string = "") => {
    triggerSnackbar(title, message, 'info');
}

export const showSuccessMessage = (title: string, message: string = "") => {
    triggerSnackbar(title, message, 'success');
}

export const showWarningMessage = (title: string, message: string = "") => {
    triggerSnackbar(title, message, 'warning');
}
