import styled from "styled-components";

const CardContainer = styled.div`
    --gridColumnCount: 3;
    --gridRowGap: 60px;
    --gridColumnGap: 10px;
    display: grid;
    grid: auto/repeat(var(--gridColumnCount),1fr);

    justify-items: center;
    align-items: center;
    row-gap: var(--gridRowGap);
    column-gap: var(--gridColumnGap);
    width: 100%;
    max-width: 1200px;
    padding: 10px;

    @media (max-width: 560px) {
        --gridColumnCount: 1;
    }
`;

const StatCard = styled.div`
    max-width: 366px;
    height: 100%;
    padding: 26px 26px 23px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(46, 91, 255, 0.1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3{
        font-family: "GilroyBold";
        font-size: 35px;
        font-weight: bold;
        color: #2e5bff;
    }

    p{
        font-family: 'GilroyMedium';
        font-size: 18px;
        font-weight: 500;
        color: #1a273d;
        text-align: center;
        margin-bottom: 0;
    }

    @media (max-width: 560px) {
        height: 149px;
    }
`;


const CenteredFlex = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;


// const InfoCard = styled.div`
//     width: 232px;
//     border-top: ${props => `1px solid ${props.borderColor}`};
//     padding: 16px 16px 0 0;

//     h3{
//         font-family: "GilroyBold";
//         font-size: 20px;
//         font-weight: bold;
//         font-stretch: normal;
//         color: #1a273d;
//     }

//     p{
//         font-family: 'GilroyMedium';
//         opacity: 0.75;
//         font-size: 16px;
//         font-weight: 500;
//         color: #1a273d;
//     }
// `;


export default function StatCards() {
    return (
        <CenteredFlex>

            <CardContainer>
                <StatCard>
                    <h3>95%</h3>
                    <p>Des dossiers complets en moins de 24h</p>
                </StatCard>
                <StatCard>
                    <h3>10</h3>
                    <p>Jours de réduction des délais de signature</p>
                </StatCard>
                <StatCard>
                    <h3>8 973</h3>
                    <p>Syndics connectés à Paperas.se à l'heure actuelle</p>
                </StatCard>




                {/* <InfoCard borderColor="#2e5bff">
                    <h3>CONNAITRE</h3>
                    <p>Avoir la liste de tout ce que vous devez fournir comme documents.</p>
                </InfoCard>

                <InfoCard borderColor="#1432a1">
                    <h3>RÉCUPÉRER</h3>
                    <p>Laisser notre robot chercher les documents de votre copropriété.</p>
                </InfoCard>

                <InfoCard borderColor="#ff8700">
                    <h3>PRÉ-ÉTAT DATÉ</h3>
                    <p>Recevoir un pré-état daté pour 60€ seulement et livré en 24h.</p>
                </InfoCard>

                <InfoCard borderColor="#ff5cc2">
                    <h3>CENTRALISER</h3>
                    <p>Que ce soit un bien en copropriété ou une maison indivituelle, vous pouvez gérer tous vos mandats.</p>
                </InfoCard>

                <InfoCard borderColor="#08ca86">
                    <h3>AUTOMATISER</h3>
                    <p>Collecter les documents à votre place grâce à notre robot de récupération automatique.</p>
                </InfoCard>

                <InfoCard borderColor="#a808ca">
                    <h3>COMMANDER</h3>
                    <p>Bénéficier de réductions pour vos achats (titre de propriété, diagnostics ou d'un état hypothécaire).</p>
                </InfoCard> */}


            </CardContainer>

        </CenteredFlex>
    );
}