import jwtDecode from 'jwt-decode';
import { UserRegistrationData } from 'services/auth';
import config from '../config';
import Api, { uninterceptedApi } from "./Api";
export const AUTH_COOKIE_KEY = "session";
export const AUTH_TOKENS_KEY = "tokens";

// Error Codes
export const AUTH_ERROR = "AUTH_ERROR";


export interface ITokenClaims {
    email: string;
}


class AuthService {

    /**
     * Performs a redirect to the login screen, this interaction is usually done by 
     * axios post request interceptor once an API response is 401.
     */
    redirectToLogin(errorCode: string) {
        let connectionUrl = '/connexion';
        if (errorCode) { connectionUrl = connectionUrl + '?error_code=' + errorCode }

        if (window.location.pathname !== connectionUrl) {
            window.location.replace(connectionUrl);
        }
    }

    /**
    * Performs a redirect to the home screen. 
    */
    redirectToHomeScreen() { window.location.replace('/') };


    // =============================================================================
    // ======================   ACCESS TOKENS SERVICES   ===========================
    // =============================================================================

    /**
     * Verifies that a JTW exists and returns if the user is authenticated.
     */
    isAuthenticated() {
        const tokens = this.getTokens();
        if (tokens && typeof tokens === 'object') {
            return !!tokens.refresh && !!tokens.access;
        }
        return false;
    }

    /**
     * Get access and refresh token from an existing user and callback token. 
     * @param {string} encodedEmail Base64 encoded email for the user to authenticate
     * @param {string} callbackToken Callback token to exchange for an authentication token (access and refresh)
     */
    async getAccessToken(encodedEmail: string, callbackToken: string) {
        const result = await Api.get(`/auth/token?email_b64=${encodedEmail}&token=${callbackToken}`);
        this.storeTokens(result.data.tokens);
        return result.data;
    };

    async refreshAccessToken() {
        const tokens = this.getTokens();
        const result = await uninterceptedApi.post('/auth/token/refresh', { refresh: tokens.refresh });
        this.storeAccessToken(result.data.access);
        return result.data.access;
    };

    storeTokens(tokens: any) {
        localStorage.setItem(`${config.ENVIRONMENT_TRIGRAM}.${AUTH_TOKENS_KEY}`, JSON.stringify(tokens));
    }

    removeTokens() {
        localStorage.removeItem(`${config.ENVIRONMENT_TRIGRAM}.${AUTH_TOKENS_KEY}`);
    }

    getTokens() {
        const accessTokens = localStorage.getItem(`${config.ENVIRONMENT_TRIGRAM}.${AUTH_TOKENS_KEY}`);
        if (!accessTokens) {
            return null;
        }
        return JSON.parse(accessTokens);
    }

    storeAccessToken(accessToken: any) {
        const tokens = this.getTokens();
        this.storeTokens({ access: accessToken, refresh: tokens.refresh });
    }


    // Returns a dict of jwt token claims
    getTokenClaims(): ITokenClaims | undefined {
        const tokens = this.getTokens();
        if (!tokens) {
            return undefined;
        }
        return jwtDecode(tokens.access);
    }

    // =============================================================================
    // =============================================================================



    /**
     * Creates a new unconfirmed user and a property asociated with it.
     */
    async createPropertyAndSendLoginLink(registerData: UserRegistrationData) {
        const result = await Api.post("/auth/register/email", registerData);
        return result.data;
    }

    /**
     * Send a login link to an existing user.
     * @param {string} email email identifying the user. 
     */
    async sendLoginLink(email: string) {
        const result = await Api.post("/auth/email", { email });
        return result.data;
    }

    /**
     * Send a password reset link to an existing user.
     */
    async sendPasswordResetLink(email: string) {
        const result = await Api.post("/auth/password/reset", { email });
        return result.data;
    }

    /**
     * Validate a password reset.
     */
    async resetPassword(emailBase64: string, token: string, password: string) {
        const result = await Api.post("/auth/password/reset/validate",
            { email_b64: emailBase64, token, password });
        return result.data;
    }

    /**
     * Authenticate a user via email/password.
     */
    async authenticateWithPassword(email: string, password: string) {
        const body = { email, password };
        const result = await Api.post('/auth/password/authenticate', body);
        this.storeTokens(result.data.tokens);
        return result.data;
    }

    /**
     * Get the authenticated user details like id, email and account type.
     */
    async getProfileDetails() {
        const result = await Api.get("/auth/me");
        return result.data;
    }

    /**
      * Gets a websocket connection ticket to create an open socket
      */
    async getWebsocketConnectionTicket() {
        const result = await Api.get("/auth/websockets/ticket");
        return result.data;
    }

    /**
     * Creates a user naively with just a login link. 
     * Normally this flow is used when onboarding commercial prospects. 
     */
    async registerWithoutValidation(email: string, commercialProspectId?: string) {
        const body = {
            email,
            commercial_prospect_id: commercialProspectId
        };
        const result = await Api.post('/auth/register/no-validation', body);
        this.storeTokens(result.data.tokens);
        return result.data;
    }


}

export default new AuthService();
