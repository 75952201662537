import { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import HeroImg from "../../../static/images/artwork/homeHero.svg";
// import trustpilotStar from '../../../static/images/home/trustpilotStar.png';
import { Button } from '../../common/SharedComponents';

const HeroContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url(${HeroImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    height: 85vh;
    min-height: 650px;
    max-height: 750px;
    width: 100%;
    padding-top: 4rem;

    .hero-text{ 
        text-align:center;
        font-family: "GilroyBold";
        font-weight: bold;
        color: #1a273d;
    }

    .hero-text>h3{font-size: 48px;}

    p{
        max-width: 550px;
        font-family: "GilroySemiBold";
        font-size: 18px;
        font-weight: 600;
        color: #1a273d;
        padding: 10px;
    }


    @media (max-width: 600px) { 
        padding-top: 2rem;
        background-image: none;     
        height: auto;

        height: fit-content;
        min-height: auto;
        margin-bottom: 80px;

        .hero-text>h3{
            font-size: 36px;
            padding: 10px;
        }
    }
`;

// const StarContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     margin-top: 5px;
//     & img{ margin: 0 2px;}
// `;


// https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-to-a-single-page-application
const TrustBox = () => {
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = useRef(null);
    useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) { window.Trustpilot.loadFromElement(ref.current, true); }
    }, []);

    return (
        !window.Trustpilot ? null :
            <>
                <div ref={ref} // We need a reference to this element to load the TrustBox in the effect.
                    className="trustpilot-widget rem-2-top-margin"
                    data-locale="fr-FR"
                    data-template-id="5419b6a8b0d04a076446a9ad"
                    data-businessunit-id="5f747f634747db0001dfbadc"
                    data-style-height="24px" data-style-width="100%" data-theme="light">
                    <a href="https://fr.trustpilot.com/review/paperas.se" target="_blank" rel="noopener noreferrer">Trustpilot</a>
                </div>
                {/* <StarContainer>
                    <a href="https://fr.trustpilot.com/review/paperas.se" target="_blank" rel="noopener noreferrer">
                        <img src={trustpilotStar} width="21" height="21" alt="Star" />
                        <img src={trustpilotStar} width="21" height="21" alt="Star" />
                        <img src={trustpilotStar} width="21" height="21" alt="Star" />
                        <img src={trustpilotStar} width="21" height="21" alt="Star" />
                        <img src={trustpilotStar} width="21" height="21" alt="Star" />
                    </a>
                </StarContainer> */}
            </>
    );
};


function Hero(props) {

    const isSmallDevice = window.screen.availWidth < 650;

    return (
        <HeroContainer>
            <div className="hero-text">
                <h3>Gagnez du temps pour <br />
                    <span style={{ color: "#2e5bff" }}>rassembler les documents</span> <br />
                    de tous vos mandats !
                </h3>

                <p className="rem-2-top-margin">Paperas.se se charge de chercher et vérifier tous les documents à votre place.</p>

                <Button style={{ padding: "0 2rem", height: "48px", marginTop: "10px" }}
                    onClick={() => props.history.push('/inscription')}>
                    {isSmallDevice ? "S'inscrire" : "Tester gratuitement"}
                </Button>
                <TrustBox />
            </div>
        </HeroContainer>
    );
}

export default withRouter(Hero);