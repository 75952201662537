import commonConfig from './common';

const config = {
    ENVIRONMENT_TRIGRAM: "dev",
    PAPERASSE_BACKEND_URL: "127.0.0.1:8000",

    // GOOGLE ANALYTICS
    GOOGLE_ANALYTICS_ID: "INVALID-ID",

    // STRIPE PAYMENT
    STRIPE_PUBLIC_API_KEY: "pk_test_51HmP7kGTAZVlzAtxhkC5T1kDIEDo0nsScwPfkcjrtkiVQ0GJQScSr1Q1ub61L8QwFswzYKrqkteOF9CRpxnFOpqH001s8TWjRA",

    SCRAPING_VALIDATE_CREDENTIALS: false
}

const developConfig = { ...commonConfig, ...config }
export default developConfig;