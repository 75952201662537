export enum AccountType {
    BASIC = "BASIC",
    PRO_ON_DEMAND = "PRO_ON_DEMAND",
    PRO_UNLIMITED = "PRO_UNLIMITED",
    ENTERPRISE_MASTER = "ENTERPRISE_MASTER",
    ENTERPRISE_AGENT = "ENTERPRISE_AGENT",
    GUEST = "GUEST"
}

export enum AccountTypeName {
    BASIC = "Basic",
    PRO_ON_DEMAND = "Pro à la demande",
    PRO_UNLIMITED = "Pro Illimité",
    ENTERPRISE_MASTER = "Pack Pro Illimité",
    ENTERPRISE_AGENT = "Agent",
    GUEST = "Invité",
}