import { BackButton } from "components/common/SharedComponents";
import Card from "components/common/cards";
import InputField from "components/common/forms/fields/InputField";
import AddressAutocomplete from "components/home/AddressAutocomplete";
import Header from "components/home/Header";
import PortfolioTypePicker from "components/home/PortfolioTypePicker2";
import { setUserConsent } from "helpers/cookieConsentmentUtils";
import { getErrorMessage } from "helpers/exceptionUtils";
import useRegistrationCode, { REGISTRATION_CODE_LOCAL_STORAGE_KEY } from "hooks/useRegistrationCode";
import { PortfolioTypes } from "models/enums/DocumentEnums";
import { ChangeEvent, SyntheticEvent, useRef, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import AuthService from "services/AuthService";
import styled from "styled-components";



const SliderCSSOverrides = styled.div`
  .slick-list {overflow: initial;};
  .slick-track {display: flex;};
`;

const sliderAnimationDuration = 400;

const settings = {
  infinite: false,
  autoplay: false,
  draggable: false,
  speed: sliderAnimationDuration,
  slidesToShow: 1,
  slidesToScroll: 1,
  centered: true,
  accessibility: false,
  arrows: false,
  adaptiveHeight: true,
};

function SignUpCentered() {

  const history = useHistory();

  const sliderRef = useRef<any>();
  const [slideIndex, setSlideIndex] = useState(0);

  const [email, setEmail] = useState<string>("");
  const [portfolioType, setPortfolioType] = useState<PortfolioTypes>(PortfolioTypes.APARTMENT_SALE_PORTFOLIO);
  const [mainAddress, setMainAddress] = useState("");
  const [addressExtraLine, setAddressExtraLine] = useState("");

  const [showAddressExtraLine, setShowAddressExtraLine] = useState(false);

  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // If there is a registration code in the querystring we save it localstorage to be used in the account creation
  const registrationCode = useRegistrationCode();

  const register = async (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setErrorMessage("");
    setButtonDisabled(true);
    try {
      const registerData = {
        email: email,
        portfolio_type: portfolioType as PortfolioTypes,
        main_address: mainAddress,
        address_extra_line: addressExtraLine,
        registration_code: registrationCode
      }
      await AuthService.createPropertyAndSendLoginLink(registerData);
      setShowMessage(true);
      localStorage.removeItem(REGISTRATION_CODE_LOCAL_STORAGE_KEY);
      setUserConsent();
    } catch (error) {
      handlePrevSlide();
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      setButtonDisabled(false);
    }
  }

  const handlePrevSlide = () => {

    const cleanupForm = () => {
      setShowMessage(false);
      setPortfolioType(PortfolioTypes.APARTMENT_SALE_PORTFOLIO);
      setAddressExtraLine("");
    };

    sliderRef.current.slickPrev();
    setSlideIndex(0);
    setTimeout(() => cleanupForm(), sliderAnimationDuration);
  }

  const handleNextSlide = () => {
    sliderRef.current.slickNext();
    setSlideIndex(1);
  }

  const extraAddressLineToggle = () => {
    setShowAddressExtraLine(!showAddressExtraLine)
    setAddressExtraLine("")
  }

  const message = (
    <div id="home-confirmation-message px-[40px]">
      <h4 className="mt-5 px-[40px]">Compte créé !</h4>
      <div className="px-[40px] pb-5 mt-5">
        <h6>
          Félicitations, votre compte Paperas.se est créé, <span className="blue-highlight">nous vous avons envoyé un email
            avec un lien à cliquer pour confirmer votre inscription</span> et accéder à votre tableau
          de bord personnel Paperas.se
        </h6>
      </div>
    </div>
  );


  return (
    <div className='content !pb-0'>
      <div className="z-10 relative">

        {/* Agregar el logo centrado con el boton a la izquierda, hacer un nuevo tipo de header */}

        <Header whiteLogo={true} hideConnectionButton={true} hidePricingButton={true} />

        <div className="home-header-first-row !justify-start">
          <div className=" ml-[10px]"><BackButton onClick={() => history.push("/")} text="Retour" /></div>
        </div>
      </div>

      <div className="absolute left-0 right-0 top-0 max-h-[48vh] min-h-[48vh] w-full overflow-hidden bg-gradient-to-br from-brandLinear to-bluePrimary bg-cover bg-no-repeat md:mx-auto" />

      <div className="over flex justify-center overflow-visible">


        {/* The class below is wrapps the overflow of the slider to allow the dropdown to overflow */}
        <div className="overflow-hidden max-w-[405px] md:max-w-[510px] md:w-[510px] h-[calc(500px+20rem)]">

          <Card extra="max-w-[405px] md:max-w-[510px] md:w-[510px] h-max mx-2.5 md:mx-auto mt-[3%] mb-auto py-2.5 pt-8 md:pt-[50px]">

            <div className="flex justify-between items-center px-[40px]">
              <div className="h-10 w-10 flex items-center">
                {slideIndex > 0 && <MdArrowBack onClick={handlePrevSlide} color="#2e5bff" className="h-8 w-8 cursor-pointer" />}
              </div>
              <h3 className="mb-0 text-2xl font-bold text-gray-900 dark:text-white">Création de mon compte</h3>
              <div className="h-10 w-10"></div>
            </div>


            <SliderCSSOverrides>
              <Slider ref={sliderRef} {...settings}>

                <div className="px-[40px] pb-5">
                  <div className="mt-4">
                    <InputField type="email" value={email} variant="auth" extra="mb-3" label="Adresse email" placeholder="albert.dupont@gmail.com" id="email"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value); e.target.setCustomValidity("") }} />

                    <div className="error-message">{errorMessage}</div>

                    <button disabled={!email} onClick={() => { handleNextSlide(); setErrorMessage("") }}
                      className="linear mt-4 w-full rounded-xl bg-bluePrimary py-3 text-base font-medium text-white transition duration-200
                active:bg-brand-700 dark:bg-brand-400 dark:text-white  dark:active:bg-brand-200 disabled:opacity-50 disabled:cursor-not-allowed">
                      Suivant
                    </button>

                    <div className="form-general-label">
                      En cliquant sur "Suivant" vous acceptez les <a href="/cgu" target="_blank" rel="noopener noreferrer">CGU</a> et
                      notre <a className="text-sm" target="_blank" href="/cookies" rel="noopener noreferrer">politique d'utilisation des cookies.</a>
                    </div>
                  </div>
                </div>


                {showMessage ? message :

                  <div className="mt-4 px-[40px] pb-5">
                    <div >
                      <p className="text-sm font-medium text-navy-700">Type de votre 1er dossier</p>
                      <PortfolioTypePicker portfolioType={portfolioType} setPortfolioType={setPortfolioType} ></PortfolioTypePicker>
                    </div>

                    <div className="form-input rem-1-top-margin">
                      <p className="text-sm font-medium text-navy-700">Adresse du bien</p>
                      <AddressAutocomplete placeholder="133 rue du commerce 75008 Paris"
                        setParentState={(autocompleteState: { mainAddress: string }) => setMainAddress(autocompleteState.mainAddress)} />

                      <div className="login-add-field">
                        <span onClick={extraAddressLineToggle}>
                          {`${showAddressExtraLine ? '-' : '+'} ajouter un complément d'adresse`}
                        </span>
                      </div>
                    </div>

                    {showAddressExtraLine &&
                      <div className="form-input mt-2">
                        <p className="text-sm font-medium text-navy-700">Complément d'adresse</p>
                        <input name="addressExtraLine" type="text" value={addressExtraLine}
                          onChange={(e) => setAddressExtraLine(e.target.value)} placeholder="5ème étage, porte Gauche, Bâtiment A" />
                      </div>
                    }

                    <button disabled={!email || !mainAddress || !portfolioType || buttonDisabled}
                      onClick={register}
                      className="linear mt-4 w-full rounded-xl bg-bluePrimary py-3 text-base font-medium text-white transition duration-200 active:bg-brand-700 
                dark:bg-brand-400 dark:text-white dark:active:bg-brand-200 disabled:opacity-50 disabled:cursor-not-allowed">
                      Créer mon compte
                    </button>

                  </div>
                }

              </Slider>
            </SliderCSSOverrides>
          </Card>
        </div>

      </div>
    </div>
  );
}

export default SignUpCentered;
