import { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import xIcon from "static/images/x.svg";
import "static/styles/snackbar.css";

interface SnackbarProps {
  messageType: string;
  timer: number;
  title: string;
  message: string;
}

/**
 * TODO: When we integrate a state manager we can have a parent component that handles the display of notifications
 * instead of mounting them using reactDOM. Also we could use that container to render multiple notifications
 * at once.
 */
function SnackBar(props: SnackbarProps) {
  const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | null>(null);

  const closeSnackBar = useCallback(() => {
    clearTimeout(closeTimeout as NodeJS.Timeout);
    // symbol ! at the end of getElementById is typescript not null assertion
    ReactDOM.unmountComponentAtNode(
      document.getElementById("notification-fixed-container")!
    );
  }, [closeTimeout]);

  const beginCloseTimeout = useCallback(() => {
    if (props.timer) {
      const timeout: NodeJS.Timeout = setTimeout(
        () => closeSnackBar(),
        props.timer
      );
      setCloseTimeout(timeout);
    }
  }, [closeSnackBar, props.timer]);

  useEffect(() => {
    beginCloseTimeout();
  }, []);

  const titleMargin = { marginBottom: !props.message ? "0" : "" };

  return (
    <div
      className={`snackbar-container ${props.messageType}-container`}
      onMouseEnter={() => clearTimeout(closeTimeout as NodeJS.Timeout)}
      onMouseLeave={() => beginCloseTimeout()}
    >
      <div>
        <div className="snackbar-info-container">
          <div>
            <div
              className={`snackbar-icon ${props.messageType}-snackbar-icon`}
            ></div>
          </div>
          <div>
            <h5 className="rubik-text" style={titleMargin}>
              {props.title}
            </h5>
            <h5 className="muted-rubik-text">{props.message}</h5>
          </div>
        </div>
        <div>
          <img
            src={xIcon}
            onClick={() => closeSnackBar()}
            alt="close icon"
            id="close-snackbar-icon"
          />
        </div>
      </div>
    </div>
  );
}

SnackBar.defaultProps = {
  title: "",
  message: "",
};

export default SnackBar;
