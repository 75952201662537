import cloneDeep from 'lodash.clonedeep';
import { DocumentStatus, DocumentTypeCodes, PortfolioTypes } from "models/enums/DocumentEnums";
import React, { createContext, useEffect, useState } from 'react';
import { PortfolioDocumentWithCode } from 'services/documents';

interface IDocumentContext {
    portfolioType: string,
    setPortfolioType: (portfolioType: PortfolioTypes) => void,
    portfolioDocuments: PortfolioDocumentWithCode[],
    setPortfolioDocuments: (documents: PortfolioDocumentWithCode[]) => void,
    updateDocument: (document: PortfolioDocumentWithCode) => void,
    validatedDocsCount: number,
    canOrderPreEtatDate: boolean,
    validDiagnosticImmo: boolean,
    validTitreProp: boolean,
    validEtatHypotecaire: boolean,
    validCertCarrez: boolean
};

export const DocumentContext = createContext<IDocumentContext>({
    portfolioType: "",
    setPortfolioType: (_portfolioType: PortfolioTypes) => { },
    portfolioDocuments: [],
    setPortfolioDocuments: (_documents: PortfolioDocumentWithCode[]) => { },
    updateDocument: (_document: PortfolioDocumentWithCode) => { },
    validatedDocsCount: 0,
    canOrderPreEtatDate: false,
    validDiagnosticImmo: false,
    validTitreProp: false,
    validEtatHypotecaire: false,
    validCertCarrez: false
});


const DocumentContainer = ({ children }: { children: React.ReactNode }) => {

    const [portfolioType, setPortfolioType] = useState("");
    const [portfolioDocuments, setPortfolioDocuments] = useState<PortfolioDocumentWithCode[]>([]);
    const [validatedDocsCount, setValidatedDocsCount] = useState(0);
    const [canOrderPreEtatDate, setCanOrderPreEtatDate] = useState(false);
    const [validDiagnosticImmo, setValidDiagnosticImmo] = useState(false);
    const [validTitreProp, setValidTitreProp] = useState(false);
    const [validEtatHypotecaire, setValidEtatHypotecaire] = useState(false);
    const [validCertCarrez, setValidCertCarrez] = useState(false);

    const updateDocument = (document: PortfolioDocumentWithCode) => {
        const docs = portfolioDocuments.map((x: PortfolioDocumentWithCode) =>
            x.code === document.code ? document : cloneDeep(x));

        setPortfolioDocuments(docs);
    };

    // Calculate validated docs count
    useEffect(() => {
        const verifiedDocs = portfolioDocuments.filter(
            document => document?.portfolio_document?.status === DocumentStatus.VERIFIED);
        setValidatedDocsCount(verifiedDocs.length);
    }, [portfolioDocuments]);

    // Calculate document order conditions
    useEffect(() => {

        const checkDocumentVerified = (documentTypeCode: DocumentTypeCodes) => {
            return portfolioDocuments.some(x => x.code === documentTypeCode
                && x?.portfolio_document?.status === DocumentStatus.VERIFIED);
        }

        const validatedCVAG = checkDocumentVerified(DocumentTypeCodes.CV_AG);
        const validatedCharges = checkDocumentVerified(DocumentTypeCodes.CHARGES);

        setCanOrderPreEtatDate(validatedCVAG && validatedCharges)

        setValidDiagnosticImmo(checkDocumentVerified(DocumentTypeCodes.DIAGNOSTICS));
        setValidTitreProp(checkDocumentVerified(DocumentTypeCodes.TITRE_PROP));
        setValidEtatHypotecaire(checkDocumentVerified(DocumentTypeCodes.ETAT_HYPOTHECAIRE));
        setValidCertCarrez(checkDocumentVerified(DocumentTypeCodes.CERT_CARREZ));

    }, [portfolioDocuments]);

    return (
        <DocumentContext.Provider
            value={{
                portfolioType,
                setPortfolioType,
                portfolioDocuments,
                setPortfolioDocuments,
                updateDocument,
                validatedDocsCount,
                canOrderPreEtatDate,
                validDiagnosticImmo,
                validTitreProp,
                validEtatHypotecaire,
                validCertCarrez
            }}>
            {children}
        </DocumentContext.Provider>
    );
};


export default DocumentContainer;
