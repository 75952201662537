import { useState } from "react";
import { getMessageFromCode } from "../../../helpers/exceptionUtils";
import AccountService from "../../../services/AccountService";
import { Button } from "../../common/SharedComponents";
import styled from "styled-components";
import logo from '../../../static/images/home/paperasseLogoBlue.svg';

const CenteredContainer = styled.div`
    align-items: center;
    display: flex;
    height: 87vh;
    &>div{ flex-grow: 1;  display: flex; flex-direction: column; align-items: center}
`;

const InnerContainer = styled.div`
    max-width: 500px;
    text-align: center;

    .message{
        margin-top: 1.5rem;
        font-family: "GilroyBold";
        font-size: 30px;
        color: #1a273d;
    };
`;

function ValidateInvitationLink(props) {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [message, setMessage] = useState("");

    const invitationToken = props.match.params.invitationToken;

    const validateInvitation = async () => {
        try {
            setButtonDisabled(true);
            await AccountService.validateInvitationLink(invitationToken);
            setMessage("L'invitation a été correctement validée. Nous vous avons envoyé un lien de connexion dans votre boîte e-mail");
            setButtonDisabled(false);
        } catch (error) {
            const errorCode = error.response.data['status'];
            if (errorCode) {
                const errorMessage = getMessageFromCode(errorCode);
                setMessage(errorMessage);
            }
            setButtonDisabled(false);
        }
    };


    const validateLinkComponent =
        <>
            <h1 className="message">Bienvenu sur Paperas.se <br/>Pour accéder à votre dossier, veuillez valider cette invitation</h1>
            <Button minheight="45px" onClick={validateInvitation} disabled={buttonDisabled} style={{marginTop: '1.5rem', padding: "0 30px"}}>Valider</Button>
        </>;

    const messageComponent = <h1 className="message">{message}</h1>;

    return (
        <>
            <CenteredContainer>
                <div>
                    <InnerContainer>
                        <div className="flex justify-center">
                        <img src={logo} width="300" alt="Paperasse logo" />
                        </div>
                        {message ? messageComponent : validateLinkComponent}
                    </InnerContainer>
                </div>
            </CenteredContainer>
        </>
    );

}

export default ValidateInvitationLink;

