

// Call an async function with a retry policy with exponential backoff
const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));
export const callWithRetry = async (fn: Function, depth = 0): Promise<any> => {
	try {
        return await fn();
	}catch(e) {
        console.log(`Error when calling retriable function: ${e}`);
        // Attempt to retry
        const backoffTime = depth > 10 ? 10000 : 2 ** depth * 10;
        console.log(`Call with retry, awaiting ${backoffTime}ms`);
		await wait(backoffTime);
		return callWithRetry(fn, depth + 1);
	}
}

export function startCrispChat() {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "3fb77382-982f-4cdd-b5b0-1cd1690d09dd";
    (function () {
        const d = document;
        const s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = Boolean(1); 
        d.getElementsByTagName("head")[0].appendChild(s);
    })();
};