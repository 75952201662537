import styled from 'styled-components';

export const HighlightedContainer = styled.div`
    border: solid 1px #f5f7ff;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 #1a273d0d;
    margin-top: ${props=> props.marginTop || '2rem'};
    padding: ${props=> props.padding || '60px'};
`;

export const Subtitle = styled.h4`
    margin-bottom: 0px;
    font-family: 'GilroyBold' !important;
    font-size: 18px !important;
    color: rgb(26, 39, 61);
`;