import { useContext } from "react";
import styled from 'styled-components';

import { clearAllCookies, refuseUserConsent, setUserConsent } from 'helpers/cookieConsentmentUtils';
import AuthService from 'services/AuthService';
import { UserContext } from "state/containers/UserContainer";

const CookieBanner = styled.div`
  max-width: 1100px;
  width: 90%;
  box-shadow: 0 2px 8px 0 #1a273d40;
  border: solid 1px #e0e7ff;
  border-radius:0.5rem;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  margin: 0 auto;

  .banner-btn{
    margin: 0 0.5rem;
    height: 35px;
    width: 8rem;
    @media (max-width: 526px) {
        margin-top:5px;
        width:12rem;
    }
  }

  #txt-btns-container{
    @media (min-width: 1024px) {
        display:flex;
    }
  }
`;

const FlexHorizontalContainer = styled.div`
    display:flex;
    justify-content: space-around;
    align-items:center;

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
`;

const InnerContainer = styled.div`
    margin: 0.3rem;
    @media (max-width: 526px) {
        margin: 0 0.3rem 0.3rem 0.3rem;
    }
`;

const ButtonContainers = styled(InnerContainer)`
    display:flex;
    justify-content:center;
    align-items: center;
    @media (max-width: 526px) {
        flex-direction:column-reverse;
    }
`;

const CookieIcon = styled.div`
  margin-right:1rem;
  &:before {
    content: '🍪';
    font-size: 35px;
  }
`;

const RubikRegularText = styled.span`
    font-family: "RubikRegular";
    font-size: 14px;
    font-weight: 500;
    color: #1a273d;
`;

const BaseButton = styled.button`
    font-family: "GilroySemiBold";
    border-radius: 2px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    border: none;
`;

const PrimaryButton = styled(BaseButton)`
    background-color: #2e5bff;
    color: #ffffff;
`;

const SecondaryButton = styled(BaseButton)`
    background-color: #e8eeff;
    color: #2e5bff;
`;

export default function CookieConsentBanner() {

    const { showCookieBanner, setShowCookieBanner } = useContext(UserContext);

    return showCookieBanner ? <CookieConsent close={() => setShowCookieBanner(false)} /> : null;
}

function CookieConsent({ close }: { close: () => void }) {

    const { setAllowedCookies } = useContext(UserContext);

    const acceptCookies = () => {
        setUserConsent();
        setAllowedCookies(true);
        close();
    };

    const refuseCookies = () => {
        refuseUserConsent();
        clearAllCookies();
        AuthService.removeTokens();
        close();
    };

    return (
        <CookieBanner>
            <FlexHorizontalContainer>
                <InnerContainer><CookieIcon /></InnerContainer>
                <InnerContainer id="txt-btns-container">
                    <InnerContainer style={{ textAlign: "center" }}>
                        <RubikRegularText>Nous respectons votre vie privée. En continuant à naviguer sur ce site, vous acceptez l'utilisation de cookies qui permettent d'améliorer votre navigation.
                            <a href="/cookies" target="_blank" rel="noopener noreferrer"> En savoir plus</a>
                        </RubikRegularText>
                    </InnerContainer>
                    <ButtonContainers>
                        <SecondaryButton className="banner-btn" onClick={refuseCookies}>Refuser</SecondaryButton>
                        <PrimaryButton className="banner-btn" onClick={acceptCookies}>J'accepte</PrimaryButton>
                    </ButtonContainers>
                </InnerContainer>
            </FlexHorizontalContainer>
        </CookieBanner>
    );
}

