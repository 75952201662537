import React, { createContext, useContext, useEffect, useState } from 'react';

import { AccountType } from 'models/enums/UserEnums';
import { UserContext } from 'state/containers/UserContainer';

interface IPortfolioContext {
    canCreatePortfolio: boolean,
}

export const PortfolioContext = createContext<IPortfolioContext>({
    canCreatePortfolio: false
});

const PortfolioContainer = ({ children }: { children: React.ReactNode }) => {

    const userContainer = useContext(UserContext);

    const [canCreatePortfolio, setCanCreatePortfolio] = useState<boolean>(false);

    const verifyUserCanCreatePortfolio = (): boolean => {
        const organization_config = userContainer.user?.organization_config;

        if (organization_config && typeof organization_config.portfolio_with_ped === 'boolean'
            && organization_config.portfolio_with_ped) {
            return true;
        }
        if (userContainer.hasAccountType([AccountType.BASIC])) {
            const portfolio_count = userContainer.user?.portfolio_count;
            return portfolio_count === 0 ? true : false;
        } else {
            return userContainer.hasAccountType([AccountType.ENTERPRISE_AGENT, AccountType.ENTERPRISE_MASTER,
            AccountType.PRO_UNLIMITED, AccountType.PRO_ON_DEMAND]) ? true : false;
        }
    };

    useEffect(() => {
        const userCanCreatePortfolio: boolean = verifyUserCanCreatePortfolio();
        setCanCreatePortfolio(userCanCreatePortfolio);
    }, [userContainer.user]);

    return (
        <PortfolioContext.Provider value={{ canCreatePortfolio }}>
            {children}
        </PortfolioContext.Provider>
    );
};

export default PortfolioContainer;