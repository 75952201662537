import axios from "axios";

import appConfig from 'config';
import AuthService, { AUTH_ERROR } from "services/AuthService";

// this list of endpoints will not redirect to the login screen if the response is 401
// this is done to avoid leftover background tasks to redirect the user.
const NON_REDIRECTION_URLS = [
    '/auth/me',
    '/auth/websockets/ticket'
];

const api = axios.create({
    baseURL: `${appConfig.PROTOCOL}://${appConfig.PAPERASSE_BACKEND_URL}/`,
    headers: { "Content-Type": "application/json" }
});

/**
 *  --- ACCESS TOKEN INTERCEPTOR ---
 * Pre-request interceptor for adding the auth token in all routes.
 */
api.interceptors.request.use(
    config => {
        const authTokens = AuthService.getTokens();
        if (authTokens)
            config.headers["Authorization"] = `Bearer ${authTokens.access}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

/**
 *  --- RESPONSE AUTHENTICATE INTERCEPTOR ---
 * Interceptor meant to be executed after the refresh token interceptor, if the 
 * request still fails with status 401 after refreshing the access token we redirect to 
 * the login screen.
 */
api.interceptors.response.use(
    response => {
        return response;
    },
    async error => {

        const redirectUserToLogin = () => {
            if (!NON_REDIRECTION_URLS.includes(error.response.config.url)) {
                AuthService.removeTokens();
                AuthService.redirectToLogin(AUTH_ERROR);
            }
        };

        //Handle Unauthorized response
        const originalRequest = error.config;
        if (error?.response?.status === 401) {
            if (!originalRequest._retry) {
                // Refresh the access token and retry the previous request.
                originalRequest._retry = true;
                try {
                    const newAccessToken = await AuthService.refreshAccessToken();
                    api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                    return api(originalRequest);
                } catch (e) {
                    return redirectUserToLogin();
                }
            } else {
                // If the request failed even with a new access token we redirect the user to the login
                redirectUserToLogin();
            }
        }
        return Promise.reject(error);
    }
);

export const uninterceptedApi = axios.create({
    baseURL: `${appConfig.PROTOCOL}://${appConfig.PAPERASSE_BACKEND_URL}/`,
    headers: { "Content-Type": "application/json" }
});

export default api;