import InputField from "components/common/forms/fields/InputField";
import { ChangeEvent } from "react";
import { useHistory } from "react-router-dom";

function AuthTypeCard({ nextSlideDisabled, handleNextSlide, email, setEmail }:
    {
        nextSlideDisabled: boolean, handleNextSlide: () => void, email: string, setEmail: (email: string) => void
    }) {

    const history = useHistory();

    return (
        <div>
            <p className="mt-6 text-sm font-medium text-navy-700">Pour vous reconnecter, veuillez saisir votre adresse e-mail:</p>

            <InputField type="email" value={email} variant="auth" extra="mb-3" label="Email" placeholder="albert.dupont@gmail.com" id="email"
                onChange={(e: ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value); e.target.setCustomValidity("") }} />

            <button disabled={nextSlideDisabled || !email} onClick={handleNextSlide}
                className="linear mt-4 w-full rounded-xl bg-bluePrimary py-3 text-base font-medium text-white transition duration-200 active:bg-brand-700 dark:bg-brand-400 
                dark:text-white dark:active:bg-brand-200 hover:cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed">
                Suivant
            </button>


            <div className="my-8 flex items-center gap-3">
                <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
            </div>

            <div>
                <p className="text-sm font-medium text-navy-700">Vous n'avez pas encore de compte ?</p>
                <button
                    onClick={() => history.push('/inscription')}
                    className="mt-4 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer
                     dark:bg-navy-700 dark:text-white text-[#2e5bff] font-semibold disabled:opacity-50 disabled:cursor-not-allowed">
                    Creer un compte
                </button>
            </div>

        </div>
    );

}

export default AuthTypeCard;