import { useState } from "react";
import { PiEyeClosed, PiEyeLight } from "react-icons/pi";

// Custom components
function PasswordField(props: {
  id: string;
  label: string;
  extra?: string;
  placeholder: string;
  variant?: string;
  state?: string;
  disabled?: boolean;
  onChange?: any;
  value?: string;
}) {
  const {
    label,
    id,
    extra,
    placeholder,
    variant,
    state,
    disabled,
    onChange,
    value,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"}`}>
        {label}
      </label>

      <div className="relative">
        <span className="absolute inset-y-0 right-2 flex items-center pl-2 text-gray-700 focus-within:text-gray-700">
          <button onClick={() => setShowPassword(!showPassword)} className="p-1 focus:outline-none focus:shadow-outline">
            {showPassword ? <PiEyeLight className="w-6 h-6" /> : <PiEyeClosed className="w-6 h-6" />}
          </button>
        </span>

        <input
          value={value}
          onChange={onChange}
          disabled={disabled}
          type={showPassword ? 'text' : 'password'}
          id={id}
          placeholder={placeholder}
          className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
                ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                : "border-gray-200 dark:!border-white/10 dark:text-white"
            }`}
        />
      </div>
    </div >
  );
}

export default PasswordField;
