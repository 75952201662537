import axios from 'axios';
import qs from 'qs';
import React from "react";
import Autosuggest from 'react-autosuggest';

import appConfig from '../../config';
import debounce from 'lodash.debounce';

import '../../static/styles/autocomplete.css';

const INPUT_DEBOUNCE = 300; // Delay of the debounce fnc in miliseconds
const INPUT_MIN_FETCH_LENGTH = 3; // Minimum length of the value to trigger a fetch

const getSuggestions = async value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
        return [];
    }

    const queryParams = {
        q: inputValue,
        //  type: 'housenumber', 
        autocomplete: 1
    }
    const result = await axios.get(`${appConfig.BAN_API_URL}?${qs.stringify(queryParams)}`);
    return result.data['features'] || [];
};

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div className="address-suggestion">
        <p>{suggestion.properties.label}</p>
        <span>{suggestion.properties.context}</span>
    </div>
);

export default class AddressAutocomplete extends React.Component {
    constructor() {
        super();

        this.state = {
            value: '',
            suggestions: []
        };
        this.getSuggestionValue = this.getSuggestionValue.bind(this);
        // Debounced fetch suggestions to let the user finish typing
        this.onSuggestionsFetchRequested = debounce(this.onSuggestionsFetchRequested.bind(this), INPUT_DEBOUNCE);
    }

    // When suggestion is clicked, Autosuggest needs to populate the input based on the clicked suggestion.
    getSuggestionValue(suggestion) {
        return `${suggestion.properties.label}. ${suggestion.properties.context}`;
    };

    onChange = (event, { newValue }) => {

        this.props.setParentState({
            mainAddress: newValue,
        });

        this.setState({
            value: newValue
        });
    };

    // Autosuggest will call this function every time you need to update suggestions.
    onSuggestionsFetchRequested = async ({ value }) => {
        if (value.length > INPUT_MIN_FETCH_LENGTH) {
            const suggestions = await getSuggestions(value);
            this.setState({ suggestions });
        }
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;

        const inputProps = {
            placeholder: this.props.placeholder || 'Type an address',
            autoComplete: 'no',
            value: this.props.value || value,
            onChange: this.onChange
        };

        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
        );
    }
}