
import styled from 'styled-components';
import useTitleHighlight from '../../../hooks/useTitleHighlight';
import calendar from '../../../static/images/icons/calendar.svg';
import StarIcon from '../../../static/images/icons/starWhite.svg';
import { Button, Quote } from '../../common/SharedComponents';
import Header from '../Header';
import UserRatingCarousel from "../UserRatingCarousel";
import AboutCards from './AboutCards';
import Contact from './Contact';
import GifContainer from './GifContainer';
import Hero from './Hero';
import PartnerLineup from './PartnerLineup';
import StatCards from './StatCards';
import Video from './Video';

const CenteredContainer = styled.div`
    display: flex;
    justify-content:center;
    width: 100%;
`;

const MiniTitle = styled.h5`
  opacity: 0.8;
  font-family: 'GilroyBold';
  font-size: 20px;
  font-weight: bold;
  color: #2e5bff;
`

const SubtitleContainer = styled.div`
    display:flex;
    align-self: center;
    justify-content: center;
    margin:1rem;
    #title-text{
        font-family: "GilroyBold";
        color: #1a273d;
        opacity: 0.9;
        font-size: 35px;
        margin-left:5px;
        text-align: center;
    }
    #subtitle-logo{ height: 37px;  }
`;

// const Watermark = styled.img`
//     position: absolute;
//     margin-top: 1rem;
//     margin-left: 9rem;
//     transform: rotate(330deg);
//     opacity: 0.5;

//     @media (max-width: 1260px) { 
//         display: none;
//     }
// `;

function Landing(props) {

    useTitleHighlight();

    return (
        <div>
            <Header hideSignupButton={false} />
            <Hero />

            <PartnerLineup />

            <div className="rem-3-top-margin"><StatCards /></div>

            <SubtitleContainer style={{ marginTop: '4rem' }}>
                <span id="title-text">Un produit unique pour les <span className='highlight'>professionnels</span> de l'immobilier et les <span className='highlight'>particuliers</span></span>
            </SubtitleContainer>

            <div className="rem-3-top-margin"><AboutCards /></div>

            <CenteredContainer className="rem-3-top-margin">
                <Button color='green' style={{ border: 'none', width: '284px', height: '50px' }}
                    className='flex items-center justify-center'
                    onClick={() => window.open('http://calendly.com/antoine-82/60', '_blank')}>
                    <img src={calendar} width="21" height='21' alt="Calendar" style={{ marginRight: '15px' }} />
                    Demander une démo
                </Button>
            </CenteredContainer>

            <SubtitleContainer style={{ marginTop: '5rem' }}>
                <span id="title-text"><span className='highlight'>Accélérez</span> les ventes et <span className='highlight'>économisez</span> sur les mandats</span>
            </SubtitleContainer>

            <GifContainer />

            <CenteredContainer className="rem-3-top-margin">

                <Button color='green' style={{ border: 'none', width: '284px', height: '50px' }}
                    className='flex items-center justify-center'
                    onClick={() => props.history.push('/offrepro')}>
                    <img src={StarIcon} width="21" height='21' alt="Star" style={{ marginRight: '15px' }} />
                    Découvrir les offres pros
                </Button>
            </CenteredContainer>

            <div style={{ marginTop: '5rem' }}>
                <CenteredContainer><MiniTitle>Le témoignage du moment :</MiniTitle></CenteredContainer>
                <SubtitleContainer className="rem-3-top-margin">
                    <span id="title-text">« <span className='highlight'>Quel gain de temps</span> et de stress en moins pour signer un compromis de vente ! »</span>
                </SubtitleContainer>
                <div style={{ marginTop: "50px" }}>
                    <UserRatingCarousel />
                </div>
            </div>

            <div className='light-gray-bg'>
                <SubtitleContainer style={{ paddingTop: '3rem' }}>
                    <span id="title-text">Paperas.se passe <span className='highlight'>à la télé !</span></span>
                </SubtitleContainer>

                <CenteredContainer className="rem-3-top-margin" style={{ flexWrap: 'wrap-reverse' }}>
                    <div style={{ display: 'flex', alignItems: 'center', maxWidth: '700px' }}>
                        <Quote>Les agents immobiliers et notaires ont l'ensemble des documents en 2 jours dont le Pré-état daté.<br /><br />
                            On a déjà livré plus d'un millier de Pré-états datés qui ont tous été validés par les notaires.</Quote>
                    </div>
                    <Video />
                </CenteredContainer>
            </div>

            <div className="light-gray-bg" style={{ paddingTop: "3rem" }}>
                <Contact />
            </div>

        </div>
    );
}

export default Landing;