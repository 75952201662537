import { useEffect } from 'react';
import '../static/styles/highlight.css';

function useTitleHighlight() {

    useEffect(() => {
        // Add the highlight effect on scroll
        const observer = new IntersectionObserver(entries => {
            // We will fill in the callback later...
            entries.forEach(entry => {
                // If the element is visible
                if (entry.isIntersecting) {
                    // Add the animation class
                    entry.target.classList.add('trigger-highlight');
                }
            });
        });
        // Tell the observer which elements to track
        document.querySelectorAll('.highlight').forEach((element) => {
            if (element) {
                observer.observe(element);
            }
        });
    }, []);

    return null;
}

export default useTitleHighlight;