import { TertiaryButton } from "components/common/SharedComponents";
import PasswordField from "components/common/forms/fields/PasswordField";

import { setUserConsent } from 'helpers/cookieConsentmentUtils';
import { getErrorMessage, showErrorMessage } from "helpers/exceptionUtils";
import { ChangeEvent, SyntheticEvent, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import AuthService from "services/AuthService";


const MESSAGES = {
    LOGIN_LINK_SENT: 'LOGIN_LINK_SENT',
    PASSWORD_RESET_LINK_SENT: 'PASSWORD_RESET_LINK_SENT'
}

function ConnectionMethodCard({ email, message, setMessage }: { email: string, message: string, setMessage: (m: string) => void }) {

    const [loginLinkButtonDisabled, setLoginLinkButtonDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // Change password
    const [password, setPassword] = useState('');
    const [passwordButtonDisabled, setPasswordButtonDisabled] = useState(false);
    const [passwordResetBtnDisabled, setPasswordResetBtnDisabled] = useState(false);


    const sendLoginLink = async (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setLoginLinkButtonDisabled(true);
        try {
            await AuthService.sendLoginLink(email);
            setMessage(MESSAGES.LOGIN_LINK_SENT);
            setUserConsent();
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(errorMessage);
            setLoginLinkButtonDisabled(false);
        }
    }

    const authenticate = async (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setPasswordButtonDisabled(true);
        try {
            const response = await AuthService.authenticateWithPassword(email, password);
            if (response.redirect_url) {
                window.location.replace(response.redirect_url);
            }
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            setErrorMessage(errorMessage);
            setPasswordButtonDisabled(false);
        }
    };

    const getPasswordResetLink = async (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setPasswordResetBtnDisabled(true);
        try {
            await AuthService.sendPasswordResetLink(email);
            setMessage(MESSAGES.PASSWORD_RESET_LINK_SENT);
            setUserConsent();
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            showErrorMessage(errorMessage);
            setPasswordResetBtnDisabled(false);
        }
    }


    const linkSentMessage =
        <div id="home-confirmation-message" className="mt-8">
            <h4>Félicitations ! Votre adresse e-mail a bien été vérifiée!</h4>
            <div><h6>Pour vous reconnecter à votre Tableau de Bord personnel Paperas.se <span className="blue-highlight">veuillez consulter votre
                boîte e-mail</span> depuis ce navigateur et cliquer sur le lien sécurisé que nous venons de vous envoyer.</h6>
            </div>
        </div>;

    const passwordResetLinkMessage =
        <div id="home-confirmation-message" className="mt-8">
            <div><h6>Pour définir ou modifier votre mot de passe Paperas.se <span className="blue-highlight">veuillez consulter votre
                boîte e-mail</span> depuis ce navigateur et cliquer sur le lien sécurisé que nous venons de vous envoyer.</h6>
            </div>
        </div>;

    return (
        <>
            {!message &&
                <div>
                    <p className="mt-6 text-sm font-medium text-navy-700">Recevoir un nouveau lien de connexion à la boite mail
                        <span className='text-[#2e5bff]'>{` ${email}`}</span></p>

                    <button
                        onClick={sendLoginLink}
                        disabled={loginLinkButtonDisabled}
                        className="flex justify-center items-center gap-3 cursor-pointer linear w-full rounded-xl bg-bluePrimary py-3 text-base font-medium text-white transition duration-200 
                         active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:active:bg-brand-200 disabled:opacity-50 disabled:cursor-not-allowed">
                         <AiOutlineMail size={"22px"} title="Envelope" />Recevoir le lien
                    </button>

                    <div className="my-6 flex items-center gap-3">
                        <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                        <p className="mb-0 text-base font-medium text-gray-600"> ou </p>
                        <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                    </div>

                    <p className="text-sm font-medium text-navy-700">Se connecter avec votre mot de passe:</p>

                    <PasswordField
                        value={password}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        variant="auth" extra="mb-3" label="Mot de passe" placeholder="" id="password" />

                    <div className="error-message">{errorMessage}</div>

                    <button disabled={!email || !password || passwordButtonDisabled} onClick={authenticate}
                        className="linear mt-3 w-full rounded-xl bg-bluePrimary py-3 text-base font-medium text-white transition duration-200 
                        active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:active:bg-brand-200 disabled:opacity-50 disabled:cursor-not-allowed">
                        Se connecter
                    </button>

                    <div className="mt-3 flex items-right justify-between">
                        <TertiaryButton
                            disabled={passwordResetBtnDisabled}
                            onClick={getPasswordResetLink}> Définir ou modifier mon mot de passe
                        </TertiaryButton>

                    </div>
                </div>
            }

            {message === MESSAGES.LOGIN_LINK_SENT && linkSentMessage}
            {message === MESSAGES.PASSWORD_RESET_LINK_SENT && passwordResetLinkMessage}

        </>

    );
}

export default ConnectionMethodCard;