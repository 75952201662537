import { useState } from 'react';
import styled from 'styled-components';

import { getErrorMessage } from '../../../helpers/exceptionUtils';
import { showErrorMessage, showSuccessMessage } from '../../../helpers/exceptionUtils';
import ContactService from '../../../services/ContactService';
import mapPin from '../../../static/images/icons/map-pin.svg';
import mail from '../../../static/images/icons/mail.svg';
import contactBackground from '../../../static/images/home/contactBackground.png';
import { Button, Input, TextArea } from '../../common/SharedComponents';

const MapContainer = styled.div`
    background: url(${contactBackground});
    background-size: cover;
    background-position: center;
`;

const ColumnContainer = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
`;

const InputContainer = styled(ColumnContainer)`
    max-width: 500px !important;
`;

const ContactInput = styled(Input)`
    width:100%;
    border: 1px solid rgba(46, 91, 255, 0.3);
`;

const ContactTextArea = styled(TextArea)`
    border: 1px solid rgba(46, 91, 255, 0.3);
    width: 100%;
`;


const CenterContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 10px 0 10px;

    .contact-title{
        font-family: 'GilroyBold';
        font-size: 35px;
        color: #ffffff;
        text-align: center;
    }
`;

const ContactFormContainer = styled.div`
    height: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #ffffff;
    padding: 40px;

    @media (min-width: 900px) {
        width: 850px !important;
    }

    @media (min-width: 500px) {
       width: 80%;
    }  

`;

const SplitContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    & div{
        flex-grow: 1;
    }
`;

const ContactDetailsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width:100%;
    max-width: 1100px;
    gap: 20px;
`;

const ContactDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
        font-family: 'GilroySemiBold';
        font-size: 20px;
        color: #ffffff;
    };
`;

function Contact() {

    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [message, setMessage] = useState("");

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const sendMinimalContactEmail = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setButtonDisabled(true);
        try {
            const messagePayload = {
                email: userEmail,
                name: userName,
                message: message
            }
            await ContactService.sendMinimalContactMessage(messagePayload);
            showSuccessMessage("Le message de contact a été envoyé", "L'équipe paperas.se vous contactera rapidement");
            setUserEmail("");
            setUserName("");
            setMessage("");
            setButtonDisabled(false);
        } catch (error) {
            const errorMessage = getErrorMessage(error) || "Une erreur s'est produite lors de l'envoi du message de contact";
            showErrorMessage(errorMessage);
            setButtonDisabled(false);
        }
    };

    return (
        <MapContainer>

            <CenterContent>
                <div className='contact-title rem-3-top-margin'>
                    <h3>Une question ? Contactez nous !</h3>
                </div>

                <ContactDetailsContainer className='rem-3-top-margin'>

                    <ContactDetails>
                        <img src={mapPin} width="21" alt="MapPin" style={{ marginRight: '15px' }} />
                        <p>15 rue d'Aligre 75012 Paris</p>
                    </ContactDetails>
                    <ContactDetails>
                        <img src={mail} width="21" alt="MapPin" style={{ marginRight: '15px' }} />
                        <p>contact@paperas.se</p>
                    </ContactDetails>

                </ContactDetailsContainer>

                <ContactFormContainer className='rem-3-top-margin'>

                    <form onSubmit={(event) => sendMinimalContactEmail(event)}>
                        <SplitContainer className='rem-2-top-margin'>
                            <div>
                                <div>
                                    <InputContainer>
                                        <ContactInput value={userName} onChange={e => setUserName(e.target.value)} placeholder="Nom" />
                                    </InputContainer>
                                </div>
                                <div>
                                    <InputContainer>
                                        <ContactInput value={userEmail} onChange={e => setUserEmail(e.target.value)} placeholder="Adresse email" />
                                    </InputContainer>
                                </div>
                                <div>
                                    <InputContainer>
                                        <ContactInput value={userPhone} onChange={e => setUserPhone(e.target.value)} placeholder="Téléphone (optionnel)" />
                                    </InputContainer>
                                </div>
                            </div>
                            <div>
                                <InputContainer>
                                    <ContactTextArea value={message} onChange={e => setMessage(e.target.value)}
                                        rows="8" placeholder="Votre message" />
                                </InputContainer>
                            </div>
                        </SplitContainer>

                        <ColumnContainer>
                            <Button type="submit" disabled={buttonDisabled || !userEmail || !message || !userName} style={{ width: '230px' }}>Envoyer</Button>
                        </ColumnContainer>
                    </form>

                </ContactFormContainer>
            </CenterContent>
        </MapContainer>
    );
}

export default Contact;