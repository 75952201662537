import { MouseEventHandler, useEffect, useRef } from "react";
import Slider from "react-slick";
import styled from 'styled-components';
import star from "static/images/home/star.svg";
import iad from "static/images/partners/iad.png";
import stephanePlaza from "static/images/partners/stephane_plaza_mini.png";
import welmo from "static/images/partners/welmo.png";
import orpi from "static/images/partners/orpi.png";
import weloge from "static/images/partners/weloge.png";


const reviews = [
    {
        name: "Orpi LMI",
        professional: true,
        syndic: orpi,
        logoHeight: "35px",
        logowidth: "120px",
        stars: 5,
        review: "Très bon service avec une interface simple et automatisé. Service client au petits soins avec ses clients. Un soulagement de ne plus courir après les syndics qui mettent des semaines à vous répondre. Nos clients sont ravis de ne plus payé le pré état daté. Bravo pour votre qualité de travail !"
    },
    {
        name: "Jela",
        professional: false,
        stars: 5,
        review: `Excellent service permettant de réaliser de vraies économies lors de la vente d'un appartement en se substituant aux états datés des syndic souvent vendus entre 200 et 380€ (prix plafonné depuis).
        La collecte des documents est simple et, en cas de besoin, le support est présent en ligne pour vous assister.
        J'ai reçu mon pré état daté en 24h comme convenu, c'est parfait.`
    },
    {
        name: "Cédric Montandon",
        professional: false,
        syndic: weloge,
        logoHeight: "35px",
        logowidth: "35px",
        stars: 5,
        review: `Rapide et efficace !
        Très simple d'utilisation, sans parler du prix qui est bien moins cher que les tarifs proposés par les syndics.
        Expérience personnelle :
        Création d'un dossier et demande d'un pré état daté un lundi à 19h (j ai fait appel au tchat pour cette première utilisation, réponse immédiate). Pré état daté complet dès le mardi matin 8h. Quand le syndic demandait 10jours avant d'envoyer ces mêmes documents !
        Je recommande vivement Paperas.`
    },
    {
        name: "Marie",
        professional: false,
        syndic: stephanePlaza,
        logoHeight: "35px",
        logowidth: "35px",
        stars: 5,
        review: "Très bien, je recommande Paperas.se"
    },
    {
        name: "Sylvain Thomas",
        professional: false,
        stars: 5,
        review: "Je ne connaissais pas ce service (et à ma connaissance, ça n'existe pas ailleurs) avant d'avoir été conseillé. Eh bien, je suis ravi d'avoir fait appel à eux ! Ils sont réactifs, très pros, et surtout transparents, ce qui est rassurant. Côté tarif, pour être tranquille et être sûr de répondre aux exigences administratives, ça en vaut largement la chandelle.  Bref, un service novateur et une prestation 5 étoiles qui mérite qu'on parle de lui !"
    },
    {
        name: "Marie",
        professional: false,
        stars: 5,
        review: "Service fiable et réactif. J'ai eu un support efficace pour la réalisation de mon pré état daté. Plus efficace que mon propre Syndic et moins cher ! Le document a été accepté par mon notaire. Je recommande !"
    },
    {
        name: "Xavier Cardoso",
        professional: true,
        syndic: welmo,
        logoHeight: "27px",
        logowidth: "100px",
        stars: 5,
        review: "En tant qu'agent immobilier, je suis ravi de leurs services. Très efficace, réactifs et rapide. Je recommande vivement."
    },
    {
        name: "Sarah El Younsi",
        professional: false,
        stars: 5,
        review: "Tellement pratique ! tellement simple ! surtout pour les gens comme moi allergique à l'administratif.. MERCI Paperas.se !"
    },
    {
        name: "Ludivine Dentelle",
        professional: true,
        syndic: iad,
        logoHeight: "40px",
        logowidth: "40px",
        stars: 4,
        review: `Très satisfaite de ce système rapide et efficace
        Super contact avec le chat
        Merci`
    },
    {
        name: "Sébastien",
        professional: false,
        stars: 5,
        review: "Grâce à Paperasse, j'ai réussi à rassembler très facilement tous les documents dont j'avais besoin pour vendre mon appart. C'est un service bien pensé, simple d'usage, et qui m'a permis en plus de faire des économies sur mon pré-état daté et d'autres documents. Je recommande vivement Paperasse à tous ceux qui vendent leur appartement !!!"
    }
];

const RatingSectionContainer = styled.div`    

    .slick-slider{
        overflow-x: hidden;
    }
    .slick-slide {
        padding: 35px 15px;
    }
    .next-arrow{
        right: 5px;
        transform: translate(-50%,-50%) rotate(45deg);
        border-style: solid;
        border-width: 3px 3px 0 0;
        border-color: rgb(46, 91, 255) !important;
        z-index: 10;
    }

    .prev-arrow{
        left: 25px;
        transform: translate(-50%,-50%) rotate(-135deg);
        border-style: solid;
        border-width: 3px 3px 0 0;
        border-color: rgb(46, 91, 255) !important;
        z-index: 10;
    }

    .prev-arrow::before, .next-arrow::before{ content: "" }

    @media (min-width: 640px) {
        .slick-list{
            margin: 0 30px;
        }
    }

`;

const UserRatingContainer = styled.div`
    width: 100%;
    min-height:220px;
    border: solid 1px #e8eeff;
    border-radius:2px;
    padding:20px;
    box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);

    .inline-container{
        display:flex;
        align-items:center;
    }

    .spread{ justify-content:space-between; }
    .opaque{  opacity: 0.5;  }
    .star-container > div:not(:first-child){  margin-left: 5px;  }
`;

const UserName = styled.h4`
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: bold;
    color: #1a273d;
    margin-bottom:0;
`;

const ReviewContainer = styled.div`
    margin: 20px 0 0;
    font-family: "Montserrat";
    font-size: 14px;
    color: #1a273d;
    /* 4 LINE ELLIPSIS */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* number of lines to show (N) */
`;

// https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-to-a-single-page-application
const TrustBox = () => {
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = useRef(null);
    useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) { window.Trustpilot.loadFromElement(ref.current, true); }
    }, []);

    return (
        !window.Trustpilot ? null :
            <>
                <div ref={ref} // We need a reference to this element to load the TrustBox in the effect.
                    className="trustpilot-widget"
                    data-locale="fr-FR"
                    data-template-id="5419b6a8b0d04a076446a9ad"
                    data-businessunit-id="5f747f634747db0001dfbadc"
                    data-style-height="24px" data-style-width="100%" data-theme="light">
                    <a href="https://fr.trustpilot.com/review/paperas.se" target="_blank" rel="noopener noreferrer">Trustpilot</a>
                </div>
            </>
    );
};


interface UserRatingProps {
    name: string;
    review: string;
    stars: number;
    syndic?: string;
    logoWidth?: string;
    logoHeight?: string;
}

const UserRating = (props: UserRatingProps) => {

    const getStars = () => {
        return (<div className="inline-container star-container">
            {Array(5).fill(undefined).map((_, index) => {
                return index < props.stars ? <div key={index}><img src={star} alt="star" /></div>
                    : <div className="opaque" key={index}><img src={star} alt="star" /></div>
            })}
        </div>);
    };

    return (
        <UserRatingContainer>
            {getStars()}
            <div className="inline-container spread" style={{ marginTop: "5px", height: "27px" }}>
                <UserName>{props.name}</UserName>
                {/* {props.professional && <ProBadge>PRO</ProBadge>} */}
                {props.syndic && <img src={props.syndic}
                    width={props.logoWidth} height={props.logoHeight}
                    style={{ height: props.logoHeight, width: props.logoWidth }}
                    alt="syndic temoignage" />}
            </div>
            <ReviewContainer>{props.review}</ReviewContainer>
        </UserRatingContainer>
    );
};


interface ArrowProps {
    className?: string;
    style?: object;
    onClick?: MouseEventHandler;
}

function SampleNextArrow({ className, style, onClick }: ArrowProps) {
    return (<div className={`${className} next-arrow`} style={{ ...style, display: "block" }} onClick={onClick} />);
}

function SamplePrevArrow({ className, style, onClick }: ArrowProps) {
    return (<div className={`${className} prev-arrow`} style={{ ...style, display: "block" }} onClick={onClick} />);
}


export default function UserRatingCarousel() {

    const carouselBreakpoints = [
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            }
        }
    ];

    const settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        draggable: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centered: false,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow className="next-arrow" />,
        responsive: carouselBreakpoints
    };

    return (
        <RatingSectionContainer className="rating-container">
            <TrustBox />
            <Slider {...settings}>
                {reviews.map((reviewObj, i) => <UserRating {...reviewObj} key={i} />)}
            </Slider>
        </RatingSectionContainer>
    );
}
