import qs from 'qs';
import { useEffect, useState } from 'react';

import { Button } from 'components/common/SharedComponents';
import { getMessageFromCode, showErrorMessage } from 'helpers/exceptionUtils';
import { useHistory, useLocation } from 'react-router-dom';
import AuthService, { AUTH_ERROR } from 'services/AuthService';
import logo from 'static/images/home/paperasseLogoBlue.svg';

function AuthValidation() {

    const location = useLocation();
    const history = useHistory();

    const [errorMessage, setErrorMessage] = useState("");

    const getAuthToken = async (encodedEmail: string, callbackToken: string) => {
        const DEFAULT_VALIDATION_ERROR_MESSAGE = "Une erreur s'est produite lors de la validation de votre lien de connexion, veuillez réessayer de vous connecter";
        try {
            const response = await AuthService.getAccessToken(encodedEmail, callbackToken);
            if (response.redirect_url) {
                window.location.replace(response.redirect_url);
            } else {
                setErrorMessage(DEFAULT_VALIDATION_ERROR_MESSAGE);
            }
        } catch (error: any) {
            let exceptionMessage = DEFAULT_VALIDATION_ERROR_MESSAGE;
            const errorCode = error?.response?.data?.error;
            if (typeof errorCode === "string" && errorCode != "VALIDATION_ERROR") {
                exceptionMessage = getMessageFromCode(error.response.data.error);
            }
            setErrorMessage(exceptionMessage);
        }
    };

    useEffect(() => {
        const encodedEmail = qs.parse(location.search, { ignoreQueryPrefix: true }).email_b64;
        const callbackToken = qs.parse(location.search, { ignoreQueryPrefix: true }).token;

        if (encodedEmail && callbackToken) {
            getAuthToken(encodedEmail as string, callbackToken as string);
        } else {
            showErrorMessage("Erreur d'authentification", "Essayez à nouveau de vous connecter");
            AuthService.redirectToLogin(AUTH_ERROR);
        }
    }, [location.search]);

    return (
        <div className='flex items-center flex-col h-[87vh]'>
            <div className='flex flex-col justify-center items-center grow'>
                <img src={logo} width="300" alt="Paperasse logo" />

                {errorMessage &&
                    <div className='max-w-[500px] text-center mt-8 mx-3'>
                        <h1 className=" font-[GilroyBold] text-3xl text-[#1a273d]">{errorMessage}</h1>
                        <Button minheight="45px" onClick={() => history.push("/connexion")} style={{ marginTop: '2rem', padding: "0 30px" }}>Se connecter</Button>
                    </div>
                }
            </div>

        </div>
    );
}

export default AuthValidation;