import { useState } from "react";
import qs from 'qs';
import { getErrorMessage } from '../../../helpers/exceptionUtils';
import AuthService from "../../../services/AuthService";
import Header from '../Header';
import { HighlightedContainer } from './SharedComponents';
import { useHistory } from "react-router-dom";
import PasswordInput from "../../common/forms/fields/PasswordInput";


function PasswordReset(props) {

    const [password, setPassword] = useState('');
    const [passwordValidation, setPasswordValidation] = useState('');

    const [buttonDisabled, setButtonDisabled] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [showSucessMessage, setShowSuccessMessage] = useState(false);

    const history = useHistory();

    const changePassword = async event => {
        event.preventDefault();
        event.stopPropagation();
        const encodedEmail = qs.parse(props.location.search, { ignoreQueryPrefix: true }).email_b64;
        const callbackToken = qs.parse(props.location.search, { ignoreQueryPrefix: true }).token;

        setButtonDisabled(true);
        try {
            await AuthService.resetPassword(encodedEmail, callbackToken, password);
            setShowSuccessMessage(true);
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            console.log(errorMessage);
            setErrorMessage(errorMessage);
            setButtonDisabled(false);
        }
    };

    const passwordResetForm = (
        <div style={{ maxWidth: '450px' }}>
            <h1 className="gilroy-bold-title" style={{ fontSize: '30px' }}>Réinitialisation du mot de passe</h1>

            <span className="muted-rubik-text">Le nouveau mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et au moins 8 caractères.</span>

            <form onSubmit={changePassword}>
                <div className="form-input rem-1-top-margin">
                    <label>Créer mon mot de passe</label>
                    <PasswordInput password={password} setPassword={setPassword} />

                </div>
                <div className="form-input rem-1-top-margin">
                    <label>Re-saisir le nouveau mot de passe</label>
                    <PasswordInput password={passwordValidation} setPassword={setPasswordValidation} />
                </div>

                <div className="error-message">{errorMessage}</div>
                <div id="register-button-container">
                    <button disabled={!password || !passwordValidation ||
                        password !== passwordValidation || buttonDisabled}
                        className='squared-button-blue register-button' type="submit">
                        Réinitialiser</button>
                </div>
            </form>
        </div>);

    const successMessage =
        <>
            <h1 className="gilroy-bold-title" style={{ fontSize: '30px' }}>
                Mot de passe mis à jour avec succès
            </h1>
            <button className='squared-button-blue register-button' style={{ marginTop: '1rem' }}
                onClick={() => history.push('/connexion')}>
                Se connecter</button>
        </>

    return (
        <>
            <Header hideConnectionButton={true} hidePricingButton={true} />
            <div className="centered-content" style={{ margin: '2rem 0 2rem' }}>
                <HighlightedContainer>
                    {showSucessMessage ? successMessage : passwordResetForm}
                </HighlightedContainer>
            </div>
        </>);
}

export default PasswordReset;