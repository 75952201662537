import React, { useState, createContext } from 'react';
import { Notification } from "services/documents";


interface INotificationContext {
    notifications: Notification[],
    setNotifications: (notifications: Notification[]) => void,
    addNotification: (notification: Notification) => void
}

export const NotificationContext = createContext<INotificationContext>({
    notifications: [],
    setNotifications: () => { },
    addNotification: () => { }
});


const NotificationContainer = ({ children }: { children: React.ReactNode }) => {

    const [notifications, setNotifications] = useState<Notification[]>([]);

    const addNotification = (notification: Notification) => {
        setNotifications([...notifications, notification]);
    };

    return (
        <NotificationContext.Provider value={{ notifications, setNotifications, addNotification }}>
            {children}
        </NotificationContext.Provider>
    );

};

export default NotificationContainer;