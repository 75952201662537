import Cookies from 'js-cookie';

const CONSENT_COOKIE_NAME = 'cookie-consent';
const CONSENT_COOKIE_DURATION_DAYS = 365;


export const isCookieAllowed = (): boolean => {
    // check consent cookie
    const consentCookie = Cookies.get(CONSENT_COOKIE_NAME);
    return Boolean(consentCookie) === true;
}

export const setUserConsent = () => {
    Cookies.set(CONSENT_COOKIE_NAME, "true", { expires: CONSENT_COOKIE_DURATION_DAYS });
}

export const refuseUserConsent = () => {
    Cookies.remove(CONSENT_COOKIE_NAME);
}

export const clearAllCookies = () => {
    const allCookies = Cookies.get();
    for (const key in allCookies) {
        Cookies.remove(key);
    }
};