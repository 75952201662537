import InputField from "components/common/forms/fields/InputField";
import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AuthService from "services/AuthService";



function ReconnectionCard({ nextSlideDisabled, handleNextSlide, email, setEmail }:
    { nextSlideDisabled: boolean, handleNextSlide: () => void, email: string | undefined, setEmail: (email: string) => void }) {

    const history = useHistory();

    const [authenticatedEmail, setAuthenticatedEmail] = useState<string|undefined>("")

    useEffect(() => {
        setAuthenticatedEmail(AuthService.getTokenClaims()?.email);
    }, [AuthService.getTokenClaims()?.email])


    return (
        <div>
            <p className="mt-6 text-sm font-medium text-navy-700">Me reconnecter en tant que : <span className='text-bluePrimary select-text'>{authenticatedEmail}</span></p>

            <button onClick={() => history.push('/account')}
                className="cursor-pointer linear w-full rounded-xl bg-bluePrimary py-3 text-base font-medium text-white transition duration-200 active:bg-brand-700 
                dark:bg-brand-400 dark:text-white dark:active:bg-brand-200 disabled:opacity-50 disabled:cursor-not-allowed">
                Accéder à mon Compte
            </button>

            <div className="my-6 flex items-center gap-3">
                <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                <p className="mb-0 text-base font-medium text-gray-600"> ou </p>
                <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
            </div>

            <p className="mt-6 text-sm font-medium text-navy-700">Pour vous reconnecter, veuillez saisir votre adresse e-mail:</p>

            <InputField type="email" value={email} variant="auth" extra="mb-3" label="Email" placeholder="albert.dupont@gmail.com" id="email"
                onChange={(e: ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value); e.target.setCustomValidity("") }} />

            <button disabled={nextSlideDisabled || !email}
                onClick={handleNextSlide}
                className="linear mt-4 w-full rounded-xl bg-bluePrimary py-3 text-base font-medium text-white transition duration-200 active:bg-brand-700 
                dark:bg-brand-400 dark:text-white dark:active:bg-brand-200 disabled:opacity-50 disabled:cursor-not-allowed">
                Suivant
            </button>
        </div>
    );
}

export default ReconnectionCard;