import styled from "styled-components";

const SplitPanel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .video-container{
        padding: 20px;
    }

    .demo-video{
        border-radius: 4px;
        border: 1px solid #e0e7ff;
        box-shadow: 0 8px 15px 0 #00000007;
        max-width: 900px;
        width: 100%;
    }

    @media (max-width: 1050px) {
        flex-wrap: wrap;
    }
`;

const LightCard = styled.div`
    max-width: 400px;

    .icon{
        font-size: 36px;
        margin-right: 20px;
    };
    .flex{
        display: flex;
    }
    .column{
        display: flex;
        flex-direction: column;

        h3{
            font-family: 'GilroyBold';
            font-size: 18px;
            font-weight: bold;
            color: #1a273d;
            white-space: nowrap;
        };

        p{
            opacity: 0.7;
            font-family: 'GilroyMedium';
            font-size: 18px;
            font-weight: 500;
            color: #1a273d;
        };
    }
`;


const CardContainer = styled.div`
    --gridColumnCount: 1;
    --gridRowGap: 8px;
    --gridColumnGap: 10px;

    display: grid;
    grid: auto/repeat(var(--gridColumnCount),1fr);

    row-gap: var(--gridRowGap);
    column-gap: var(--gridColumnGap);

    padding: 20px;

    @media (max-width: 1050px) {
        --gridColumnCount: 2;
    }

    @media (max-width: 560px) {
        --gridColumnCount: 1;
    }
`;

export default function GifContainer() {

    return (
        <SplitPanel className="rem-3-top-margin">
            <div className="vide-container">
                <video className="demo-video"  autoPlay loop muted playsInline>
                    <source src={require("../../../static/videos/dashboard_animation.mp4")} type="video/mp4" />
                </video>
            </div>

            <CardContainer>
                <LightCard>
                    <div className="flex">
                        <div><span className="icon">🎯</span></div>
                        <div className="column">
                            <h3>Personnalisé :</h3>
                            <p>Un tableau de bord dynamique pour chaque transaction.</p>
                        </div>
                    </div>
                </LightCard>

                <LightCard>
                    <div className="flex">
                        <div><span className="icon">🚀</span></div>
                        <div className="column">
                            <h3>Rapide :</h3>
                            <p>La récupération automatique des documents dans l'espace syndic.</p>
                        </div>
                    </div>
                </LightCard>

                <LightCard>
                    <div className="flex">
                        <div><span className="icon">👨🏻‍⚖️</span></div>
                        <div className="column">
                            <h3>Conforme :</h3>
                            <p>Contrôle de tous les documents.</p>
                        </div>
                    </div>
                </LightCard>

                <LightCard>
                    <div className="flex">
                        <div><span className="icon">👋🏼</span></div>
                        <div className="column">
                            <h3>Partagé :</h3>
                            <p>Le suivi d'avancement en temps réel et échanges facilités entre vendeurs, professionnel et notaire.</p>
                        </div>
                    </div>
                </LightCard>

                <LightCard>
                    <div className="flex">
                        <div><span className="icon">🏘</span></div>
                        <div className="column">
                            <h3>Multibiens :</h3>
                            <p>La gestion de tous vos biens.</p>
                        </div>
                    </div>
                </LightCard>
            </CardContainer>
        </SplitPanel>
    );
}