import Card from "components/common/cards";
import Header from "components/home/Header";
import ReconnectionCard from 'features/authentication/login/steps/ReconnectionCard';
import AuthTypeCard from 'features/authentication/login/steps/AuthTypeCard';
import { showErrorMessage } from "helpers/exceptionUtils";
import qs from "qs";
import { useEffect, useRef, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";
import AuthService, { AUTH_ERROR } from "services/AuthService";
import ConnectionMethodCard from "./steps/ConnectionMethodCard";
import { BackButton } from "components/common/SharedComponents";

const sliderAnimationDuration = 400;

const settings = {
  infinite: false,
  autoplay: false,
  draggable: false,
  speed: sliderAnimationDuration,
  slidesToShow: 1,
  slidesToScroll: 1,
  centered: true,
  accessibility: false,
  arrows: false,
  adaptiveHeight: true,
};

function SignInCenter() {

  const history = useHistory();

  const sliderRef = useRef<any>();
  const location = useLocation();

  const [slideIndex, setSlideIndex] = useState(0);

  const [email, setEmail] = useState<string>("");
  const [mainMessage, setMainMessage] = useState<string>("");

  useEffect(() => {
    // Show error codes in query string
    const errorCode = qs.parse(location.search, { ignoreQueryPrefix: true }).error_code;
    if (errorCode) {
      if (errorCode === AUTH_ERROR)
        showErrorMessage("Erreur d'authentification", "Essayez à nouveau de vous connecter");
    }
  }, [location.search]);


  const handleNextSlide = () => {
    sliderRef.current.slickNext();
    setSlideIndex(1);
  }

  const handlePrevSlide = () => {
    sliderRef.current.slickPrev();
    setSlideIndex(0);
    setTimeout(() => setMainMessage(""), sliderAnimationDuration);
  }

  return (
    <div className='content'>
      <div className="z-10 relative">
        <Header whiteLogo={true} hideConnectionButton={true} hidePricingButton={true} />

        <div className="home-header-first-row !justify-start">
          <div className=" ml-[10px]"><BackButton onClick={() => history.push("/")} text="Retour" /></div>
        </div>
      </div>

      <div className="absolute left-0 right-0 top-0 max-h-[48vh] min-h-[48vh] w-full overflow-hidden bg-gradient-to-br from-brandLinear to-bluePrimary bg-cover bg-no-repeat md:mx-auto" />

      <div className="flex justify-center">
        <Card extra="max-w-[405px] md:max-w-[510px] md:w-[510px] h-max mx-2.5 md:mx-auto mt-[3%] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">

          <div className="flex justify-between items-center">
            <div className="h-10 w-10 flex items-center">
              {slideIndex > 0 && <MdArrowBack onClick={handlePrevSlide} color="#2e5bff" className="h-8 w-8 cursor-pointer" />}
            </div>
            <h3 className="mb-0 text-4xl font-bold text-gray-900 dark:text-white">Connexion</h3>
            <div className="h-10 w-10"></div>
          </div>

          <Slider ref={sliderRef} {...settings}>

            <div className="px-2">

              {AuthService.isAuthenticated()
                ? <ReconnectionCard nextSlideDisabled={!sliderRef || !email} handleNextSlide={handleNextSlide} email={email} setEmail={setEmail} />
                : <AuthTypeCard nextSlideDisabled={!sliderRef || !email} handleNextSlide={handleNextSlide} email={email} setEmail={setEmail} />}

            </div>

            <div className="px-2">
              <ConnectionMethodCard email={email} message={mainMessage} setMessage={setMainMessage} />
            </div>

          </Slider>

        </Card>
      </div>
    </div>
  );
}

export default SignInCenter;
