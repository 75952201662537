
import styled from 'styled-components';
import iad from "../../../static/images/partners/iad.png";
import imop from "../../../static/images/partners/imop.png";
import casavo from "../../../static/images/partners/casavo.avif";
import orpi from "../../../static/images/partners/orpi.png";
import proprioo from "../../../static/images/partners/Proprioo.png";
import stephanePlaza from "../../../static/images/partners/stephane_plaza.png";
import welmo from "../../../static/images/partners/welmo.png";
import zefir from "../../../static/images/partners/zefir.svg";

import hosman from "../../../static/images/partners/hosman.png";
import avril from "../../../static/images/partners/avril.png";
import ici from "../../../static/images/partners/ici.png";
import benoit from "../../../static/images/partners/benoit.png";
import weloge from "../../../static/images/partners/weloge.png";
import hempton from "../../../static/images/partners/hempton.png";
import david from "../../../static/images/partners/david.png";
import notreagence from "../../../static/images/partners/notreagence.png";
import era from "../../../static/images/partners/era.svg";
import addresse from "../../../static/images/partners/addresse.png";
import cote from "../../../static/images/partners/cote.png";
import pieces from "../../../static/images/partners/5pieces.png";
import exp from "../../../static/images/partners/exp.webp";
import trudaines from "../../../static/images/partners/trudaines.png";


const PartnerRowContainer = styled.div`

    max-width: 90%;
    margin: 0 auto;
    display:flex;
    justify-content: space-evenly;
    

    @media (max-width: 700px) {
        flex-wrap: wrap;
    }
    

    .grid-container{
        --gridColumnCount: 4;
        --gridRowGap: 60px;
        display: grid;
        grid: auto/repeat(var(--gridColumnCount),1fr);
        justify-items: center;
        align-items: center;
        row-gap: var(--gridRowGap);
        width: 100%;
        
        @media (max-width: 1024px) {
            --gridColumnCount: 4;
        }
 
        @media (max-width: 500px) {
            --gridColumnCount: 2;
        }

        @media (min-width: 1024px) {
            --gridColumnCount: 8;
        }
    }
`;

const SubtitleContainer = styled.div`
    display:flex;
    align-items: center;
    margin-top: 2rem;
    justify-content: center;

    #title-text{
        white-space: nowrap;
        font-family: "GilroyBold";
        color: #1a273d;
        opacity: 0.8;
        font-size: 20px;
    }
`;

const AutoScroll = styled.div`

    --animationSpeed: 70s;
    --slideCount: 8;
    --slide-width: 240px;

    margin: auto;
    overflow:hidden;
    position: relative;
    
    @media (max-width: 500px) {
        --animationSpeed: 25s;
        --slide-width: 180px;
    }

    &::before, &::after {
        /* @include white-gradient; */
        content: "";
        /* height: 100px; */
        position: absolute;
        width: 200px;
        z-index: 2;
    }

    .slide {
        height: 80px;
        width: var(--slide-width);
    }

    .logo{
        /* padding: 10px; */
        display: flex ;
        align-items: center;
        justify-content: center;
    }
`;

const AutoScrollLeft = styled(AutoScroll)`
        @keyframes scrollLeft {
            0% { transform: translateX(0); }
            100% { transform: translateX(calc(var(--slide-width) * var(--slideCount)* -1))}
        }

        &::after {
            right: 0;
            top: 0;
            transform: rotateZ(180deg);
        }

        &::before {
            left: 0;
            top: 0;
        }

        .slide-track-left {
        animation: scrollLeft var(--animationSpeed) linear infinite;
        display: flex;
        width: calc(var(--slide-width) * (var(--slideCount) * 2) );
    }
`;

const AutoScrollRight = styled(AutoScroll)`
        @keyframes scrollRight {
            0% {transform: translateX(calc(var(--slide-width) * var(--slideCount)* -1)) }
            100% { transform: translateX(100); }
        }

        &::after {
            left: 0;
            top: 0;
            transform: rotateZ(180deg);
        }

        &::before {
            right: 0;
            top: 0;
        }

        .slide-track-right {
        animation: scrollRight var(--animationSpeed) linear infinite;
        display: flex;
        width: calc(var(--slide-width) * (var(--slideCount) * 2) );
    }
`;

export default function PartnerLineup() {

    /* 
    Autoscroll inspired by:
    https://codepen.io/studiojvla/pen/qVbQqW
    https://stackoverflow.com/questions/70442770/infinite-scrolling-carousel-css-only
    */

    return (
        <>
            <SubtitleContainer>
                <span id="title-text">Ils nous utilisent :</span>
            </SubtitleContainer>
            <PartnerRowContainer style={{ marginTop: "1.5rem" }}>
                <AutoScrollRight>
                    <div className='slide-track-right'>
                        <div className="logo slide">
                            <img src={stephanePlaza} width="110px" height="30px" alt="stephane plaza" />
                        </div>
                        <div className="logo slide">
                            <img src={imop} alt="imop" width="55px" height="55px" />
                        </div>
                        <div className="logo slide">
                            <img src={exp} alt="exp" width="70px" height="70px" />
                        </div>
                        <div className="logo slide">
                            <img src={zefir} alt="zefir" width="80px" height="27px" />
                        </div>
                        <div className="logo slide">
                            <img src={welmo} alt="welmo" width="85px" height="23px" />
                        </div>
                        <div className="logo slide">
                            <img src={proprioo} width="100px" alt="Proprioo" height="45px" />
                        </div>
                        <div className="logo slide">
                            <img src={orpi} alt="orpi" width="80px" height="48px" />
                        </div>
                        <div className="logo slide">
                            <img src={iad} alt="iad" width="45px" height="46px" />
                        </div>
                        <div className="logo slide">
                            <img src={casavo} width="80px" height="40px" alt="casavo" />
                        </div>
                        <div className="logo slide">
                            <img src={addresse} width="90px" height="55px" alt="addresse" />
                        </div>
                        <div className="logo slide">
                            <img src={pieces} width="80px" height="80px" alt="5 pieces" />
                        </div>

                        {/*  Need to duplicate slides */}

                        <div className="logo slide">
                            <img src={stephanePlaza} width="110px" height="30px" alt="stephane plaza" />
                        </div>
                        <div className="logo slide">
                            <img src={imop} alt="imop" width="55px" height="55px" />
                        </div>
                        <div className="logo slide">
                            <img src={exp} alt="exp" width="70px" height="70px" />
                        </div>
                        <div className="logo slide">
                            <img src={zefir} alt="zefir" width="80px" height="27px" />
                        </div>
                        <div className="logo slide">
                            <img src={welmo} alt="welmo" width="85px" height="23px" />
                        </div>
                        <div className="logo slide">
                            <img src={proprioo} width="100px" alt="Proprioo" height="45px" />
                        </div>
                        <div className="logo slide">
                            <img src={orpi} alt="orpi" width="80px" height="48px" />
                        </div>
                        <div className="logo slide">
                            <img src={iad} alt="iad" width="45px" height="46px" />
                        </div>
                        <div className="logo slide">
                            <img src={casavo} width="80px" height="40px" alt="casavo" />
                        </div>
                        <div className="logo slide">
                            <img src={addresse} width="90px" height="55px" alt="addresse" />
                        </div>
                        <div className="logo slide">
                            <img src={pieces} width="80px" height="80px" alt="5 pieces" />
                        </div>
                    </div>
                </AutoScrollRight>
            </PartnerRowContainer>

            <PartnerRowContainer style={{ marginTop: "0" }}>
                <AutoScrollLeft>
                    <div className='slide-track-left'>
                        <div className="logo slide">
                            <img src={hosman} width="110px" height="20px" alt="hosman" />
                        </div>
                        <div className="logo slide">
                            <img src={avril} alt="avril" width="55px" height="55px" />
                        </div>
                        <div className="logo slide">
                            <img src={ici} alt="ici" width="50px" height="50px" />
                        </div>
                        <div className="logo slide">
                            <img src={weloge} alt="weloge" width="110px" height="40px" />
                        </div>
                        <div className="logo slide">
                            <img src={trudaines} width="150px" alt="trudaines" height="80px" />
                        </div>
                        <div className="logo slide">
                            <img src={benoit} width="60px" alt="benoit" height="40px" />
                        </div>
                        <div className="logo slide">
                            <img src={hempton} alt="hempton" width="70px" height="50px" />
                        </div>
                        <div className="logo slide">
                            <img src={david} alt="david" width="70px" height="55px" />
                        </div>
                        <div className="logo slide">
                            <img src={notreagence} width="70px" height="55px" alt="notreagence" />
                        </div>
                        <div className="logo slide">
                            <img src={era} width="70px" height="60px" alt="era" />
                        </div>
                        <div className="logo slide">
                            <img src={cote} width="210px" height="50px" alt="cote" />
                        </div>
                        <div className="logo slide">
                            <img src={hosman} width="110px" height="20px" alt="hosman" />
                        </div>
                        <div className="logo slide">
                            <img src={avril} alt="avril" width="55px" height="55px" />
                        </div>
                        <div className="logo slide">
                            <img src={ici} alt="ici" width="50px" height="50px" />
                        </div>
                        <div className="logo slide">
                            <img src={weloge} alt="weloge" width="110px" height="40px" />
                        </div>
                        <div className="logo slide">
                            <img src={trudaines} width="150px" alt="trudaines" height="80px" />
                        </div>
                        <div className="logo slide">
                            <img src={benoit} width="60px" alt="benoit" height="40px" />
                        </div>
                        <div className="logo slide">
                            <img src={hempton} alt="hempton" width="70px" height="50px" />
                        </div>
                        <div className="logo slide">
                            <img src={david} alt="david" width="70px" height="55px" />
                        </div>
                        <div className="logo slide">
                            <img src={notreagence} width="70px" height="55px" alt="notreagence" />
                        </div>
                        <div className="logo slide">
                            <img src={cote} width="210px" height="50px" alt="cote" />
                        </div>
                    </div>
                </AutoScrollLeft>
            </PartnerRowContainer>

        </>
    );
}




