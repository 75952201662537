/**
 * App configuration to use, based on the environment variable REACT_APP_ENV.
 * This variable is set at startup, when we launch npm start or npm build
 * (see package.json , in the scripts section )
 * If no configuration is found, we use develop.
 */
const environment = process.env.REACT_APP_ENV || 'develop';

let config: any;
switch (environment) {
    case 'production':
        config = require('./envs/production').default;
        break;
    case 'staging':
        config = require('./envs/staging').default;
        break;
    case 'develop':
    default:
        config = require('./envs/develop').default;
        break;
}

const IS_PRODUCTION_ENV = environment === 'production';

export default { ...config, IS_PRODUCTION_ENV };

