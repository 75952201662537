import commonConfig from './common';

const config = {
    ENVIRONMENT_TRIGRAM: "rls",
    PAPERASSE_BACKEND_URL: "admin.paperas.se",

    // GOOGLE ANALYTICS
    GOOGLE_ANALYTICS_ID: "UA-174171214-1",

    // STRIPE PAYMENT
    STRIPE_PUBLIC_API_KEY: "pk_live_51HmP7kGTAZVlzAtxVLhoL5lIlPB6wZzvLyoJB87LoJQCiAQtQomadHdXbMfmazBACglz3EL1tNH9F5kVRl57hOcf00XXudl8zP",

    SCRAPING_VALIDATE_CREDENTIALS: true
}

const productionConfig = { ...commonConfig, ...config };
export default productionConfig;
