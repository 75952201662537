const defaultConfiguration = {

    PROTOCOL: process.env.REACT_APP_DEBUG ? 'http' : 'https',
    WEBSOCKET_PROTOCOL: process.env.REACT_APP_DEBUG ? 'ws' : 'wss',

    // BAN API DOCS: https://geo.api.gouv.fr/adresse
    BAN_API_URL: 'https://api-adresse.data.gouv.fr/search/',

    // Partner links B2C:
    RCP_PARTNER_LINK: "https://www.hypotheques-en-ligne.fr/commander/reglement-de-copropriete?partner=paperas&coupon=PAPERAS765",
    EDD_PARTNER_LINK: "https://www.hypotheques-en-ligne.fr/commander/etat-descriptif-de-division?partner=paperas&coupon=PAPERAS765",
    TITRE_PROPRIETE_PARTNER_LINK: "https://www.hypotheques-en-ligne.fr/commander/copie-acte-de-vente?partner=paperas&coupon=PAPERAS765",
    ALLO_DIAGNOSTIQUE_PARTNER_LINK: "https://www.allodiagnostic.com/commander/?layout=no-iframe&p=paperasse",
    ACTE_DE_NAISSANCE_PARTNER_LINK: "https://acte-civil-en-ligne.fr/acte-de-naissance/commander/?partner=paperas&coupon=PAPERAS765",
    ETAT_HYPOTHECAIRE_PARTNER_LINK: "https://www.hypotheques-en-ligne.fr/commander/etat-hypothecaire/?partner=paperas&coupon=PAPERAS985",
    DIAG_ASSAINISSEMENT_LINK: "https://lannuaire.service-public.fr",

    // Partner links B2B:
    RCP_PARTNER_LINK_PROFESSIONALS: "https://www.hypotheques-en-ligne.fr/commander/reglement-de-copropriete?partner=paperas&coupon=PAPERAS987",
    EDD_PARTNER_LINK_PROFESSIONALS: "https://www.hypotheques-en-ligne.fr/commander/etat-descriptif-de-division/?partner=paperas&coupon=PAPERAS987",
    TITRE_PROPRIETE_PARTNER_LINK_PROFESSIONALS: "https://www.hypotheques-en-ligne.fr/commander/copie-acte-de-vente?partner=paperas&coupon=PAPERAS987",
    ACTE_DE_NAISSANCE_PARTNER_LINK_PROFESSIONALS: "https://acte-civil-en-ligne.fr/acte-de-naissance/commander/?partner=paperas&coupon=PAPERAS987",
    ETAT_HYPOTHECAIRE_PARTNER_LINK_PROFESSIONALS: "https://www.hypotheques-en-ligne.fr/commander/etat-hypothecaire/?partner=paperas&coupon=PAPERAS987",


    STRIPE_PRODUCTS: {
        BASIC: {
            id: 'BASIC',
            verbose_name: 'Offre Gratuit',
            display_price: '0'
        },
        PRO_ON_DEMAND: {
            id: 'PRO_ON_DEMAND',
            verbose_name: 'Offre Pro à la demande',
            display_price: '19€ HT'
        },
        ENTERPRISE_MASTER: {
            id: 'ENTERPRISE_MASTER',
            verbose_name: 'Abonnement Pack Pro Illimité',
            display_price: '129€ HT'
        },
        PRO_UNLIMITED: {
            id: 'PRO_UNLIMITED',
            verbose_name: 'Abonnement Pro Illimité',
            display_price: '59€ HT'
        },
        ENTERPRISE_AGENT: {
            id: 'ENTERPRISE_AGENT',
            verbose_name: 'Utilisateur supplémentaire',
            display_price: '10€ HT'
        },
        PRE_ETAT_DATE_BASIC_USERS: {
            id: 'PRE_ETAT_DATE_BASIC_USERS',
            verbose_name: 'Pré-état daté',
            display_price: '60€ TTC'
        },
        PRE_ETAT_DATE_PRO_USERS: {
            id: 'PRE_ETAT_DATE_PRO_USERS',
            verbose_name: 'Pré-état daté',
            display_price: '57.60€ TTC'
        },
        PRE_ETAT_DATE_PRO_USERS_INCL_TAX: {
            id: 'PRE_ETAT_DATE_PRO_USERS_INCL_TAX',
            verbose_name: 'Pré-état daté',
            display_price: '57.60€ TTC'
        },
        PORTFOLIO_AND_PRE_ETAT_DATE: {
            id: 'PORTFOLIO_AND_PRE_ETAT_DATE',
            verbose_name: 'Pré-état daté',
            display_price: '75.00€ TTC'
        },
        PORTFOLIO_AND_PRE_ETAT_DATE_INCL_TAX: {
            id: 'PORTFOLIO_AND_PRE_ETAT_DATE_INCL_TAX',
            verbose_name: 'Pré-état daté',
            display_price: '75.00€ TTC'
        }

    },

    // SCRAPING 
    SCRAPING_CONFIG: {
        IMPOTS_GOUV_SCRAPING_DESTINATION_NAME: "IMPOTS",

        FAVOURITE_SYNDICS: [
            {
                name: 'Nexity',
                logo: './nexity.svg',
                showCity: true,
                scrapingDestinationName: 'MyNexity',
                validateCredentials: false
            },
            {
                name: 'Citya immobilier',
                logo: './citya.jpeg',
                showCity: false,
                scrapingDestinationName: '.Ics',
                validateCredentials: false
            },
            {
                name: 'Foncia',
                logo: './foncia.jpg',
                showCity: false,
                scrapingDestinationName: 'MyFoncia',
                validateCredentials: false
            },
            {
                name: 'Loiselet & Daigremont',
                logo: './loiseletDaigremont.png',
                showCity: false,
                scrapingDestinationName: 'LoiseletDaigremont',
                validateCredentials: false
            },
            {
                name: 'Oralia',
                logo: './oralia.png',
                showCity: false,
                scrapingDestinationName: 'MyOralia',
                validateCredentials: false
            },
            // {
            //     name: 'Immo de France',
            //     logo: './immoDeFrance.jpeg',
            //     showCity: false,
            //     scrapingDestinationName: 'Thetranet (SEIITRA)',
            //     validateCredentials: false
            // },
            // {
            //     name: 'Century 21',
            //     logo: './century21.png',
            //     showCity: false,
            //     scrapingDestinationName: 'Century 21',
            //     validateCredentials: false
            // },
            // {
            //     name: 'Orpi',
            //     logo: './orpi.png',
            //     showCity: false,
            //     scrapingDestinationName: 'Orchestra (Egiweb)',
            //     validateCredentials: false
            // },
            // {
            //     name: 'Square Habitat',
            //     logo: './squareHabitat.jpeg',
            //     showCity: false,
            //     scrapingDestinationName: 'Thetranet (SEIITRA)',
            //     validateCredentials: false
            // },
            // {
            //     name: 'Laforêt',
            //     logo: './laforet.jpeg',
            //     showCity: false,
            //     scrapingDestinationName: 'GERCOP',
            //     validateCredentials: false
            // }
        ]
    },
};

export default defaultConfiguration;