import qs from 'qs';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const REGISTRATION_CODE_LOCAL_STORAGE_KEY = 'registration_code';

function useRegistrationCode() {

    const location = useLocation();

    useEffect(() => {
        const registrationCode = qs.parse(location.search, { ignoreQueryPrefix: true }).registration_code;
        if (registrationCode) {
            localStorage.setItem(REGISTRATION_CODE_LOCAL_STORAGE_KEY, registrationCode);
        }
    }, [location.search]);

    return localStorage.getItem(REGISTRATION_CODE_LOCAL_STORAGE_KEY) || null;
}

export default useRegistrationCode;