import { PortfolioTypes } from "models/enums/DocumentEnums";

import { BsBuildings, BsHouseDoor } from "react-icons/bs";
import { HiOutlineBuildingOffice } from "react-icons/hi2";

import "static/styles/login.css";


interface PortfolioTypePickerProps {
    portfolioType: PortfolioTypes | undefined;
    setPortfolioType: (portfolioType: PortfolioTypes) => void;
}

function PortfolioTypePicker({portfolioType, setPortfolioType}: PortfolioTypePickerProps) {


    return (

        <div className="flex items-center flex-wrap flex-col">
            
            {/* <div
                https://tailwindcomponents.com/component/select-dropdown
            
                className="flex items-center w-full gap-4 rounded border-gray-200 border-[1px] py-2 px-4 "
                onClick={() => props.setPortfolioType(PortfolioTypes.APARTMENT_SALE_PORTFOLIO)}>
                <div className="flex justify-center items-center w-[40px] h-[35px] border-[1px] border-[#e0e7ff] rounded-full" >
                    <HiOutlineBuildingOffice className="h-5 w-5 text-gray-700" color="#2E5BFF" />
                </div>
                <div className="flex items-center w-full gap-4">
                    <span className="grow">Bien en copropriété</span>

                    <div className="flex h-7 w-[70px] items-center justify-center text-sm bg-blue-100 rounded-[10px] font-bold">
                        <div className="text-blue-500">Vente</div>
                    </div>
                    <FaCaretDown className="h-5 w-5 text-gray-300"/>
                </div>
            </div> */}

            <div
                className={`flex items-center w-full gap-4 rounded border-[1px] cursor-pointer
                py-2 px-4 ${portfolioType === PortfolioTypes.APARTMENT_SALE_PORTFOLIO ? "border-bluePrimary" : "border-white"}`}
                onClick={() => setPortfolioType(PortfolioTypes.APARTMENT_SALE_PORTFOLIO)}>

                <div className="flex justify-center items-center w-[40px] h-[35px] border-[1px] border-[#e0e7ff] rounded-full" >
                    <HiOutlineBuildingOffice className="h-5 w-5 text-gray-700" color="#2E5BFF" />
                </div>
                <div className="flex w-full gap-4">
                    <span className="grow">Bien en copropriété</span>
                    <div className="flex h-7 w-[70px] items-center justify-center text-sm bg-blue-100 rounded-[10px] font-bold">
                        <div className="text-blue-500">Vente</div>
                    </div>
                </div>
            </div>

            <div
                className={`flex items-center w-full gap-4 rounded border-[1px] cursor-pointer
                py-2 px-4 ${portfolioType === PortfolioTypes.HOUSE_SALE_PORTFOLIO  ? "border-bluePrimary" : "border-white"}`}
                onClick={() => setPortfolioType(PortfolioTypes.HOUSE_SALE_PORTFOLIO)}>

                <div className="flex justify-center items-center w-[40px] h-[35px] border-[1px] border-[#e0e7ff] rounded-full" >
                    <BsHouseDoor className="h-5 w-5 text-gray-700" color="#2E5BFF" />
                </div>
                <div className="flex w-full gap-4">
                    <span className="grow">Maison Individuelle</span>
                    <div className="flex h-7 w-[70px] items-center justify-center text-sm bg-blue-100 rounded-[10px] font-bold">
                        <div className="text-blue-500">Vente</div>
                    </div>
                </div>
            </div>


            <div
                className={`flex items-center w-full gap-4 rounded border-[1px] cursor-pointer
                py-2 px-4 ${portfolioType === PortfolioTypes.GESTION_LOCATIVE_PORTFOLIO  ? "border-bluePrimary" : "border-white"}`}
                onClick={() => setPortfolioType(PortfolioTypes.GESTION_LOCATIVE_PORTFOLIO)}>

                <div className="flex justify-center items-center w-[40px] h-[35px] border-[1px] border-[#e0e7ff] rounded-full" >
                    <BsBuildings className="h-5 w-5 text-gray-700" color="#2E5BFF" />
                </div>
                <div className="flex w-full gap-4">
                    <span className="grow">Bien en copropriété</span>
                    <div className="flex h-7 w-[70px] items-center justify-center text-sm bg-green-100 rounded-[10px] font-bold">
                        <div className="text-green-500">Gestion</div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default PortfolioTypePicker;
