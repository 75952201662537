
export enum PortfolioTypes {
  APARTMENT_SALE_PORTFOLIO = "APARTMENT_SALE_PORTFOLIO",
  HOUSE_SALE_PORTFOLIO = "HOUSE_SALE_PORTFOLIO",
  PED_PORTFOLIO = "PED_PORTFOLIO",
  PENDING_PAYMENT_PORTFOLIO = "PENDING_PAYMENT_PORTFOLIO",
  GESTION_LOCATIVE_PORTFOLIO = "GESTION_LOCATIVE_PORTFOLIO"
}

export enum DocumentCategories {
  CO_PROPERTY_DOCS = "DOCS_IMMEUBLE_COPROPRIETE",
  APARTMENT_DOCS = "DOCS_APPARTEMENT",
  PERSONAL_DOCS = "DOCS_PERSONNELS",
  DOCS_ACQUEREURS = "DOCS_ACQUEREURS",
}

export enum DocumentTypeCodes {
  // CO_PROPERTY_DOCS
  RCP_EDD = "RCP_EDD",
  FICHE_SYN = "FICHE_SYN",
  CARNET_ENTRETIEN = "CARNET_ENTRETIEN",
  CV_AG = "CV_AG",
  PV_AG = "PV_AG",
  CHARGES = "CHARGES",
  PRE_ETAT_DATE = "PRE_ETAT_DATE",
  DIAGNOSTICS_IMMEUBLE = "DIAGNOSTICS_IMMEUBLE",
  // DOCS_APPARTEMENT
  TAX_FONCIERE = "TAX_FONCIERE",
  TITRE_PROP = "TITRE_PROP",
  DIAGNOSTICS = "DIAGNOSTICS",
  CERT_CARREZ = "CERT_CARREZ",
  PLAN_DU_LOGEMENT_ET_ANEXES = "PLAN_DU_LOGEMENT_ET_ANNEXES",
  CERTIFICAT_DE_SUPERFICIE = "CERTIFICAT_DE_SUPERFICIE",
  ASSURANCES_DU_LOGEMENT = "ASSURANCES_DU_LOGEMENT",
  ENTRETIEN = "ENTRETIEN",
  LOGEMENT_LOUE = "LOGEMENT_LOUE",
  // HOUSE_DOCS
  OTHER_PROPERTY_DOCS = "OTHER_PROPERTY_DOCS",
  DIAGNOSTICS_ASSAINISSEMENT = "DIAGNOSTICS_ASSAINISSEMENT",
  ETAT_HYPOTHECAIRE = "ETAT_HYPOTECAIRE",
  DOCS_MAISON_MOINS_10_ANS = "DOCS_MAISON_MOINS_10_ANS",
  // DOCS_PERSONNELS
  ACT_NAISSANCE = "ACT_NAISSANCE",
  DOC_IDENTITE = "DOC_IDENTITE",
  RIB = "RIB",
  OTHER = "OTHER",
  // ACQUIRER_DOCS
  DOC_IDENTITE_ACQUEREURS = "DOC_IDENTITE_ACQUEREURS",
  ACT_NAISSANCE_ACQUEREURS = "ACT_NAISSANCE_ACQUEREURS",
  DOCS_AUTRE_ACQUEREURS = "DOCS_AUTRE_ACQUEREURS",
}

export enum DocumentStatus {
  NOT_ADDED = "A_AJOUTER",
  NEW_UNVERIFIED = "NEW_A_VERIFIER",
  SCRAPING_PROCESSING = "SCRAPING_PROCESSING",
  KO_SCRAPING = "KO_SCRAPING",
  REJECTED = "REJECTED",
  VERIFIED = "VERIFIED",
  INCOMPLETE = "INCOMPLETE",
  CREATION_IN_PROCESS = "CREATION_IN_PROCESS",
  EXPIRED = "EXPIRED",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  MISSING_ANNEXES_COMPTABLES = "MISSING_ANNEXES_COMPTABLES",
  MISSING_LAST_CONVOCATION = "MISSING_LAST_CONVOCATION",
  MISSING_LAST_APPEL = "MISSING_LAST_APPEL",
}

export enum PortfolioStatus {
  DOCUMENTS_TO_GET = "DOCUMENTS_TO_GET",
  DOCUMENTS_TO_VERIFY = "DOCUMENTS_TO_VERIFY",
  MANUAL_SCRAPING_TODO = "MANUAL_SCRAPING_TODO",
  REVIEWED_BY_ADMINS = "REVIEWED_BY_ADMINS",
  PROBLEM = "PROBLEM",
  COMPLETE = "COMPLETE"
}

export enum FileRetrievalMethod {
  UPLOADED_BY_USER = "UPLOADED_BY_USER",
  UPLOADED_BY_ADMIN = "UPLOADED_BY_ADMIN",
  SCRAPED = "SCRAPED",
};
