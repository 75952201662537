import React, { createContext, useEffect, useState } from 'react';

import { isCookieAllowed } from 'helpers/cookieConsentmentUtils';
import AuthService from 'services/AuthService';
import { User } from 'services/auth';
import { AccountType } from 'models/enums/UserEnums';

interface IUserContext {
    user: User | null,
    setUser: (user: User) => void,
    allowedCookies?: boolean,
    setAllowedCookies: (allowed: boolean) => void,
    showCookieBanner?: boolean,
    setShowCookieBanner: (show: boolean) => void,
    refreshUserDetails: () => void,
    hasAccountType: (accountTypes: AccountType[]) => boolean,
}


export const UserContext = createContext<IUserContext>({
    user: null,
    setUser: () => { },
    allowedCookies: false,
    setAllowedCookies: () => { },
    showCookieBanner: false,
    setShowCookieBanner: () => { },
    refreshUserDetails: async () => { },
    hasAccountType: (_accountTypes: AccountType[]) => false,
});


const UserContainer = ({ children }: { children: React.ReactNode }) => {

    const [user, setUser] = useState<User | null>(null);
    // allowed cookies is undefined initially to avoid loading a mistaken initial state.
    const [allowedCookies, setAllowedCookies] = useState<boolean | undefined>(undefined);
    const [showCookieBanner, setShowCookieBanner] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        const cookieConsent = isCookieAllowed();
        setAllowedCookies(cookieConsent);
        if (!cookieConsent) {
            setShowCookieBanner(true);
        }
    }, []);

    const refreshUserDetails = async () => {
        try {
            const userDetails = await AuthService.getProfileDetails();
            setUser(userDetails);
        } catch (error) {
            console.log("Error getting the user details");
        }
    };

    useEffect(() => {
        // Get user if not set
        const getUserDetails = async () => {
            if (!AuthService.isAuthenticated())
                return;
            if (!user) { await refreshUserDetails() }
        };
        getUserDetails();
    }, [user]);

    const hasAccountType = (accountTypes: AccountType[] = []) => {
        const userAccountType = user?.account_type;
        if (userAccountType) {
            return accountTypes.includes(userAccountType);
        }
        return false
    };

    return (
        <UserContext.Provider value={
            {
                user,
                setUser,
                allowedCookies,
                setAllowedCookies,
                showCookieBanner,
                setShowCookieBanner,
                refreshUserDetails,
                hasAccountType
            }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContainer;