import qs from 'qs';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import { BackButton } from 'components/common/SharedComponents';
import Header from 'components/home/Header';
import { getMessageFromCode } from 'helpers/exceptionUtils';
import UserLost from 'static/images/userLost.svg';

import "static/styles/login.css";

function PageNotFound() {

    const location = useLocation();
    const history = useHistory();

    const urlErrorCode = qs.parse(location.search, { ignoreQueryPrefix: true }).error;

    const pageNotFoundMessage =
        <div className='mb-[30px]'>
            <h4>Oops!</h4>
            <h4>La page que nous recherchez semble introuvable.</h4>
        </div>;

    const getMessage = () => {
        if (!urlErrorCode) {
            return pageNotFoundMessage;
        }
        return (<div className='my-4'><h4>{getMessageFromCode(urlErrorCode.toString())}</h4></div>);
    }

    return (
        <div className='content'>
            <Header />
            <div id="split-container">
                <div id="image-container">
                    <img src={UserLost} alt="User lost" />
                </div>

                <div id="register-form-container">
                    <BackButton onClick={() => history.push(`/`)}
                        text='Retour'></BackButton>

                    <div id="home-confirmation-message">
                        {getMessage()}
                    </div>

                    {!urlErrorCode &&
                        <h6 className="top-separation-2 gilroy-medium-content">Code erreur : 404</h6>}

                    <div className="top-separation-2 gilroy-medium-content">

                        {!urlErrorCode ?
                            <h6>Voici quelques liens utiles à la place :</h6> :
                            <h6>Voici quelques liens utiles :</h6>}
                        <br />
                        <a href="/faq">Aide</a><br /><br />
                        <a href="/apropos">À propos</a><br /><br />
                        <a href="/devenirpartenaire">Devenir partenaire</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(PageNotFound);