

import IconPaperasseLogo from 'static/images/paperasseLogoWhite.svg';
import "static/styles/footer.css";

export default function Footer() {

    return (
        <footer className="site-footer">
            <div id="footer-container">

                <div id="footer-logo-container">
                    <img src={IconPaperasseLogo} alt="logo white" width="200px" />
                    <p>Paperas.se a pour mission de rendre simple ce qui est compliqué !</p>
                </div>

                <div>
                    <h6>LIENS UTILES</h6>
                    <hr />
                    <p><a href="/apropos">À propos</a></p>
                    <p><a href="/devenirpartenaire">Devenir partenaire</a></p>
                    <p><a href="/faq">Aide</a></p>
                </div>

                <div>
                    <h6>LÉGAL</h6>
                    <hr />
                    <p><a href="/cgu">Conditions Générales d'Utilisation</a></p>
                    <p><a href="/cgv">Conditions Générales de Vente</a></p>
                    <p><a href="/confidentialite">Confidentialité</a></p>
                    <p><a href="/cookies">Cookies</a></p>
                    <p><a href="/mentionslegales">Mentions Légales</a></p>
                </div>

                <div>
                    <h6>CONTACT</h6>
                    <hr />
                    <p>15 rue d'Aligre 75012 Paris</p>
                    <p><a href="mailto:contact@paperas.se">contact@paperas.se</a></p>
                    {/* <p><a href="">+ 33 07 336 122</a></p> */}
                </div>

            </div>

            <div className="footer-copyright">
                Fait avec<span style={{ margin: "0 5px" }} role='img' aria-label="heart emoji">💙</span> à Paris par PAPERASSE SAS
            </div>
        </footer>
    );
}
