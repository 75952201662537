import qs from 'qs';
import styled from 'styled-components';

import { setUserConsent } from 'helpers/cookieConsentmentUtils';
import { Button, SecondaryButton } from 'components/common/SharedComponents';
import Header from './Header';

import "static/styles/header.css";

const CenteredContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const PolicyContainer = styled.div`
    border: solid 1px #f5f7ff;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 #1a273d0d;
    margin-top: 2rem;
    padding: 50px;
    max-width: 500px;

    .header{
        font-family: "GilroyBold";
        font-size: 24px;
        color: #1a273d;
    }

    .text{
        font-family: 'GilroyMedium';
        font-size: 18px;
        color: #1a273d;
    }

    .spaced-components{
        display: flex;
        justify-content: space-between;
    }
`;

const buttonStyle = {
    margin: '0 5px',
    width: '150px'
};

const PolicyConsent = (props: { location: { search: string; }; history: string[]; }) => {
    const encodedUserEmail = qs.parse(props.location.search, { ignoreQueryPrefix: true }).email_b64;
    const callbackToken = qs.parse(props.location.search, { ignoreQueryPrefix: true }).token;

    const handleAcceptCgu = () => {
        setUserConsent();
        window.location.replace(`/auth${props.location.search}`);
    };

    return (
        <>
            <Header hideConnectionButton={true} hidePricingButton={true} />
            <CenteredContainer className='content'>

                <PolicyContainer>
                    <CenteredContainer>
                        <h4 className="text" style={{ fontFamily: 'GilroyBold' }}>
                            Bienvenu sur Paperas.se, vous avez été invité à utiliser notre service.
                        </h4>
                    </CenteredContainer>

                    <div className='text rem-1-top-margin'>
                        En cliquant sur « Continuer » vous acceptez les <a href="/cgu" target="_blank" rel="noopener noreferrer">CGU</a> et notre <a target="_blank" href="/cookies" rel="noopener noreferrer">politique d'utilisation des cookies.</a>
                    </div>

                    <div className='rem-2-top-margin spaced-components'>
                        <SecondaryButton className="rem-1-top-margin" style={buttonStyle} onClick={() => props.history.push('/')}>Refuser</SecondaryButton>
                        <Button className="rem-1-top-margin" style={buttonStyle}
                            onClick={handleAcceptCgu} disabled={!encodedUserEmail || !callbackToken}>Continuer</Button>
                    </div>
                </PolicyContainer>
            </CenteredContainer>
        </>
    );
}

export default PolicyConsent;