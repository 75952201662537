import commonConfig from './common';

const config = {
    ENVIRONMENT_TRIGRAM: "stg",
    PAPERASSE_BACKEND_URL: "stagingadmin.paperas.se",

    // GOOGLE ANALYTICS
    GOOGLE_ANALYTICS_ID: "",

    // STRIPE PAYMENT
    STRIPE_PUBLIC_API_KEY: "pk_test_51HmP7kGTAZVlzAtxhkC5T1kDIEDo0nsScwPfkcjrtkiVQ0GJQScSr1Q1ub61L8QwFswzYKrqkteOF9CRpxnFOpqH001s8TWjRA",

    SCRAPING_VALIDATE_CREDENTIALS: true
}

const stagingConfig = { ...commonConfig, ...config };
export default stagingConfig;