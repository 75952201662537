import Api from "./Api";
import { MessageRequest, MinimalMessageRequest } from "services/contact";

class ContactService {
  /**
   * Sends a contact message for the admins.
   */
  async sendContactMessage(messageRequest: MessageRequest): Promise<void> {
    const result = await Api.post("mailing/contact", messageRequest);
    return result.data;
  }

  /**
   * Send a minimal contact message for the admins.
   */
  async sendMinimalContactMessage(
    messageRequest: MinimalMessageRequest
  ): Promise<void> {
    const result = await Api.post("mailing/contactminimal", messageRequest);
    return result.data;
  }
}

export default new ContactService();
