import axios from "axios";
import {
  AgentAllocationInfo,
  Branding,
  CommercialProspect,
  GetAgentAccountsResponse,
  InvitationResponse,
  UpdateBrandingRequest,
  UserInformation
} from "services/accounts";
import Api from "./Api";


class AccountService {
  /**
   * Invites a user to collaborate in a dashboard.
   * @param {UUID} dashboardId dashboard id to invite the user.
   * @param {string} guestEmail email of the user to invite.
   * @param {string} invitationMessage message for the invited user.
   */
  async sendInvitationToUser(
    portfolioId: string,
    guestEmail: string,
    invitationMessage: string
  ): Promise<InvitationResponse> {
    const result = await Api.post("/accounts/invitations", {
      portfolio_id: portfolioId,
      guest_email: guestEmail,
      invitation_message: invitationMessage,
    });
    return result.data;
  }

  /**
   * Gets the account information for the authenticated user.
   */
  async getUserInformation(): Promise<UserInformation | {}> {
    try {
      const result = await Api.get("/accounts/information");
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 404) return {};
      }
      throw error;
    }
  }

  /**
   * Updates the account information for the authenticated user.
   */
  async updateUserInformation(
    userInformation: UserInformation
  ): Promise<UserInformation | {}> {
    try {
      const result = await Api.post("/accounts/information", {
        ...userInformation,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 404) return {};
      }
      throw error;
    }
  }



  /**
 * Gets the agent allocation infrmation, like remaining agents to allocate
 */
  async getAgentAllocationInfo(): Promise<AgentAllocationInfo> {
    const result = await Api.get("/accounts/agents-allocation");
    return result.data;
  }


  /**
   * Gets a list of agents managed by the user. Implements pagination
   */
  async getAccountAgents(pageNumber: number, filter: string): Promise<GetAgentAccountsResponse> {
    const result = await Api.get("/accounts/agents", {
      params: { page: pageNumber, filter: filter },
    });
    return result.data;
  }

  /**
   * Create an agent linked to a master account
   */
  async createAgent(agentEmail: string): Promise<void> {
    await Api.post("/accounts/agents", {
      agent_email: agentEmail,
    });
  }

  /**
   * Delete an agent linked to a master account
   */
  async deleteAgent(agentId: string): Promise<void> {
    await Api.delete(`/accounts/agents/${agentId}`);
  }

  async getInvitationLink(
    portfolioId: string,
    guestEmail: string
  ): Promise<string> {
    const result = await Api.get(
      `/accounts/${portfolioId}/invitations/link/${guestEmail}`
    );
    return result.data;
  }

  async validateInvitationLink(invitationToken: string): Promise<void> {
    const body = { token: invitationToken };
    await Api.post("/accounts/invitations/validate", body);
  }




  async upsertBranding(brandingConfig: UpdateBrandingRequest): Promise<Branding> {

    const formData = new FormData();
    if (brandingConfig.logo) {
      formData.append("logo", brandingConfig.logo as unknown as string);
    }
    formData.append("logo_in_dashboard", brandingConfig.logo_in_dashboard + '');
    formData.append("logo_in_emails", brandingConfig.logo_in_emails + '');

    const requestConfig = { headers: { "Content-Type": "multipart/form-data" }, };

    const response: { data: Branding } = await Api.post('/accounts/branding',
      formData, requestConfig);

    return response.data;
  }

  async sendTestEmail(): Promise<void> {
    await Api.post('/mailing/test');
  }


  async getCommercialProspectInformation(commercialProspectId: string): Promise<CommercialProspect> {
    const result = await Api.get(`/accounts/commercial-prospect/${commercialProspectId}`);
    return result.data;
  }


}

const accountService = new AccountService(); 
export default accountService;
