import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import logo from 'static/images/home/paperasseLogoBlue.svg';
import whiteLogo from 'static/images/paperasseLogoWhite.svg';
import styled from 'styled-components';
import { Button, SecondaryButton } from '../common/SharedComponents';
import { FaRegStar } from "react-icons/fa";
import { FiUser } from "react-icons/fi";

import "static/styles/header.css";


const TerciaryButton = styled(SecondaryButton)`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: solid 1px #2e5bff;
    padding: 0 2rem;
    height: 48px;
    width: 192px;

    @media (max-width: 600px) {
        width: 158px;
        padding: 0 15px;
    }
`;

const LoginButton = styled(SecondaryButton)`
    gap: 10px;
    border: solid 1px #2e5bff;
    padding: 0 2rem;
    height: 48px;

    @media (max-width: 600px) {
        padding: 0 15px;
    }
`;

const PrimaryButton = styled(Button)`
    border: solid 1px #2e5bff;
    padding: 0 2rem;
    height: 48px;
    width: 192px;

    @media (max-width: 650px) {
        display: none;
    }
`;


type HeaderProps = RouteComponentProps & {
    hidePricingButton?: boolean;
    hideConnectionButton?: boolean;
    hideSignupButton?: boolean;
    connectionButtonBackUrl?: string;
    logoRedirectionUrl?: string;
    whiteLogo?: boolean;
}


function Header(props: HeaderProps) {

    const handleRedirection = () => {
        props.logoRedirectionUrl
            ? props.history.push(props.logoRedirectionUrl)
            : props.history.push('/');
    }

    return (
        <div>
            <div className="home-header-first-row">
                <div>
                    <div onClick={() => handleRedirection()} style={{ cursor: "pointer" }}>
                        <img src={props.whiteLogo ? whiteLogo : logo} width="230" alt="Paperasse logo" />
                    </div>
                </div>

                <div className="container-first-row-right">
                    {!props.hidePricingButton &&
                        <TerciaryButton onClick={() => props.history.push('/offrepro')}>
                            <FaRegStar size={"21px"} title='Star' />Offres Pro
                        </TerciaryButton>}

                    {!props.hideConnectionButton &&
                        <LoginButton onClick={() => props.history.push({ pathname: '/connexion', state: { backRedirectionUrl: props.connectionButtonBackUrl } })}>
                            <FiUser size={"21px"} title='User' />Se connecter
                        </LoginButton>}

                    {!props.hideSignupButton &&
                        <PrimaryButton onClick={() => props.history.push({ pathname: '/inscription', state: { backRedirectionUrl: props.connectionButtonBackUrl } })}>
                            S'inscrire
                        </PrimaryButton>}
                </div>
            </div>
        </div>
    );
}

Header.defaultProps = {
    hideSignupButton: true,
    whiteLogo: false
};

export default withRouter(Header)