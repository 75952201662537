import styled from "styled-components";


const VideoContainer = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0 1rem;

    .video{
        width: 100%;
        aspect-ratio: 16 / 9;
        border-radius: 4px;
    }
`;


export default function Video() {
    return (
        <VideoContainer>
                    <iframe
                        className="video"
                        src="https://www.youtube-nocookie.com/embed/7iuq-rrC7tg?start=7"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
        </VideoContainer>
    );
}